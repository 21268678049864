import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyledSlice } from '../styled/Slice';
import { ReactComponent as CariesIcon } from './icons/caries.svg';
import { ReactComponent as GumsIcon } from './icons/gums.svg';
import { ReactComponent as ToothWearIcon } from './icons/toothWear.svg';
import { ReactComponent as ExplorerIcon } from './icons/explorer.svg';
import { ReactComponent as AlignmentIcon } from './icons/alignment.svg';

import styles from './MenuSlice.module.css';

export const explorersIcons = {
  caries: <CariesIcon />,
  gums: <GumsIcon />,
  toothWear: <ToothWearIcon />,
  alignment: <AlignmentIcon />,
  explorer: <ExplorerIcon />
};

const MenuSlice = ({ explorer, fontSize }) => {
  const sliceRef = useRef(null);
  const textClasses = explorer.enabled ? styles.inactiveExplorer : styles.disabledExplorer;

  useEffect(() => {
    if (explorer.enabled) {
      const currentRef = sliceRef.current;
      const activateExplorer = async () => await explorer.activateExplorer();

      currentRef.addEventListener('click', activateExplorer);
      return () => {
        currentRef.removeEventListener('click', activateExplorer);
      };
    }
  }, [explorer]);

  return (
    <StyledSlice
      key={explorer.id}
      contentHeight="160px"
      attrs={{
        ref: sliceRef,
        enabled: `${explorer.enabled}`,
        explorer: `${explorer.id}`,
        loading_state: 'false'
      }}
    >
      <div className={styles.explorerContent}>
        <div className={styles.explorerIcon} id="explorer-icon">
          {explorersIcons[explorer.id]}
        </div>
        <div className={textClasses} id={explorer.id} style={{ fontSize }}>
          {explorer.title}
        </div>
      </div>
    </StyledSlice>
  );
};

MenuSlice.propTypes = {
  explorer: PropTypes.shape({
    /**
     * Explorer id
     */
    id: PropTypes.string.isRequired,
    /**
     * Call back to active explorer
     */
    activateExplorer: PropTypes.func.isRequired,
    /**
     * explorer title
     */
    title: PropTypes.string.isRequired,
    /**
     * Is explorer enabled boolean
     */
    enabled: PropTypes.bool.isRequired
  }),
  /**
   * calculated font size
   */
  fontSize: PropTypes.string
};

export default MenuSlice;
