import React from 'react';
import { utils, cacheManager, cacheKeys } from '@web-3d-tool/shared-logic';
import styles from './Splitter.module.css';
import styles360 from './Splitter360.module.css';

const Splitter = (props) => {
  const {
    renderComp,
    propsMatrix,
    isModelCompareActive,
    imageFrameDimentions = { width: window.innerWidth / 4 + 32, drawerWidth: 32 },
    isSplittedViewWithSidePluginActive,
    translationsForSplitter,
  } = props;
  const is360 = utils.getIs360HubEnabled();

  const getModelCompareDate = (orderKey) => {
    const dateObj = cacheManager.get(cacheKeys.SELECTED_ORDER_DATE) || {};
    return dateObj ? utils.formatDateAndTime(dateObj[orderKey]).formattedDate : 'Invalid Date';
  };

  return (
    <div className={styles.container}>
      {propsMatrix.map((propsArr, i) => (
        <div
          key={i}
          className={is360 ? styles360.splitterRow : styles.splitterRow}
          style={{ height: `${100 / propsMatrix.length}%` }}
        >
          {propsArr.map((componentProps, j) => (
            <div
              style={{
                width:
                  isSplittedViewWithSidePluginActive && is360
                    ? `calc(( 100vw - ${imageFrameDimentions?.width}px + ${imageFrameDimentions?.drawerWidth}px ) / ${propsArr.length}  )`
                    : `100%`,
                height: '100%',
              }}
              key={j}
            >
              {renderComp(componentProps, i, j)}
              {propsArr.length === 2 && is360 && (
                <div className={styles360.splitterRowSign}>
                  {j === 0
                    ? (() => {
                        return isModelCompareActive ? getModelCompareDate('selected') : translationsForSplitter.lower;
                      })()
                    : (() => {
                        return isModelCompareActive ? getModelCompareDate('current') : translationsForSplitter.upper;
                      })()}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default Splitter;
