import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useThree } from 'react-three-fiber';

import {
  isTextureMappingExistInGeometry,
  isTextureMappingExistInTextures,
  MaterialName
} from '@web-3d-tool/shared-logic';

import MarginLine from './MarginLine/MarginLine';
import { MeshWithTexture } from './Textures/MeshWithTexture';
import {
  initFallbackShaders,
  shouldRenderMeshWithoutTMIfFallbackFails
} from './Fallback/FallbackMeshWithTextureMapping';

const Scene = props => {
  const { meshes, onMount } = props;
  const [shouldRenderMeshes, setShouldRenderMeshes] = useState(false);
  const { invalidate } = useThree();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  initFallbackShaders({ forceUpdate });

  useEffect(() => {
    onMount();
    setShouldRenderMeshes(true);
    normalizeCanvasByScreenSize();

    window.addEventListener('resize', normalizeCanvasByScreenSize);

    return () => {
      window.removeEventListener('resize', normalizeCanvasByScreenSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    invalidate();
  });

  return (
    <>
      {shouldRenderMeshes && (
        <group>
          {meshes.map(({ geometry, material, modelName, textures = [] }) => (
            <Fragment key={geometry.uuid}>
              {material.type !== 'LineBasicMaterial' && (
                <Fragment>
                  {shouldRenderMeshWithoutTM(textures, material, geometry) && (
                    <mesh geometry={geometry} material={material} />
                  )}
                  {textures.map(texture => (
                    <MeshWithTexture
                      texture={texture}
                      geometry={geometry}
                      modelName={modelName}
                      key={`${geometry.uuid}_${texture.name}`}
                      shouldRenderTextureMapping={!shouldRenderMeshWithoutTM(textures, material, geometry)}
                    />
                  ))}
                </Fragment>
              )}

              {material.type === 'LineBasicMaterial' && <MarginLine geometry={geometry.geometry} />}
            </Fragment>
          ))}
        </group>
      )}
    </>
  );
};

const shouldRenderMeshWithoutTM = (textures, material, geometry) => {
  const noTextures = !textures || textures.length === 0;
  return (
    noTextures ||
    material.name === MaterialName.chromatic ||
    shouldRenderMeshWithoutTMIfFallbackFails() ||
    !isTextureMappingExistInGeometry(geometry) ||
    !isTextureMappingExistInTextures(textures)
  );
};

const normalizeCanvasByScreenSize = () => {
  //this prevents blinking of 3D model in the first 10-15 seconds of launching the viewer if browser not set on 100% zoom level.
  // ITEROBIZ-57950 explains it in details.
  const currentBrowserZoomScalePercentage = Math.round((window.outerWidth / window.innerWidth) * 100);
  const canvas = document.querySelectorAll('canvas')[0];
  if (currentBrowserZoomScalePercentage < 100) {
    canvas.style.height = '0px';
  }
};

Scene.propTypes = {
  meshes: PropTypes.arrayOf(
    PropTypes.shape({
      geometry: PropTypes.object,
      material: PropTypes.object,
      modelName: PropTypes.string,
      textures: PropTypes.arrayOf(
        PropTypes.shape({
          map: PropTypes.object,
          material: PropTypes.object,
          name: PropTypes.string
        })
      )
    })
  ),
  /**
   * Callback, fired when Scene is rendered
   */
  onMount: PropTypes.func
};

Scene.defaultProps = {
  meshes: [],
  onMount: () => {}
};

export default Scene;
