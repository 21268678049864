import React from 'react';
import { utils, cacheManager, cacheKeys } from '@web-3d-tool/shared-logic';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackMeshWithTextureMapping from '../Fallback/FallbackMeshWithTextureMapping';

export const MeshWithTextureMapping = props => {
  const { map, geometry, textureName } = props;
  const scanOriginLumina = utils.getIsScanOriginLumina();
  const jawsPluginCurrentState = cacheManager.get(cacheKeys.JAW_STATE);

  const isComponentRefreshedByError = (() => {
    const shaderErrorEntry = cacheManager.get(cacheKeys.FALLBACK_SHADER_ERROR);

    if (shaderErrorEntry) {
      const { error, count } = shaderErrorEntry;
      return error && count && count > 0;
    }
    return false;
  })();

  if (!textureName.includes('texture_mapping')) {
    return null;
  }

  const RenderMeshWithTextureMapping = () => {
    return (
      <mesh geometry={geometry}>
        {scanOriginLumina && !isComponentRefreshedByError ? (
          <phongTextureMaterial
            name="PhongTextureMaterial"
            attach="material"
            texture={map}
            args={[map, jawsPluginCurrentState, props]}
            map={map}
            map-flipY={false}
          />
        ) : (
          <textureMaterial name="TextureMaterial" attach="material" texture={map} map={map} map-flipY={false} />
        )}
      </mesh>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        return <FallbackMeshWithTextureMapping geometry={geometry} map={map} error={error} />;
      }}
    >
      {isComponentRefreshedByError ? (
        <FallbackMeshWithTextureMapping geometry={geometry} map={map} />
      ) : (
        <RenderMeshWithTextureMapping />
      )}
    </ErrorBoundary>
  );
};
