import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils } from '@web-3d-tool/shared-logic';
import stylesHourglass from './HourglassProgress/HourglassProgress.module.css';
import HourglassImage from './HourglassProgress/HourglassImage';
import SpinnerProgress from './SpinnerProgress/SpinnerProgress';

const LoadingProgress = props => {
  const {
    className: classNameProp,
    width,
    height,
    classNameContainerProp,
    classNameSpinnerProp,
    spinnerVarProps,
    ...other
  } = props;
  const is360 = utils.getIs360HubEnabled();
  const className = classNames(stylesHourglass.spinning, classNameProp);

  return (
    <>
      {is360 ? (
        <SpinnerProgress
          width={width}
          height={height}
          classNameContainerProp={classNameContainerProp}
          classNameSpinnerProp={classNameSpinnerProp}
          spinnerVarProps={spinnerVarProps}
        />
      ) : (
        <HourglassImage className={className} {...other} />
      )}
    </>
  );
};

LoadingProgress.propTypes = {
  /**
   *
   */
  className: PropTypes.string,
  /**
   * Height of hourglass image
   */
  height: PropTypes.string,
  /**
   * Width of hourglass image
   */
  width: PropTypes.string
};

export default LoadingProgress;
