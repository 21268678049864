import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConditionNavigation.module.css';
import ConditionButton from './ConditionButton/ConditionButton';
const ConditionNavigation = ({ explorers, activeExplorer }) => {
  return (
    <div className={styles.navigationContainer}>
      {explorers.map(explorer => (
        <ConditionButton key={explorer.id} explorer={explorer} isActive={explorer.id === activeExplorer} />
      ))}
    </div>
  );
};

ConditionNavigation.propTypes = {
  /**
   * Array of explorers
   */
  explorers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Explorer id
       */
      id: PropTypes.string.isRequired,
      /**
       * Call back to active explorer
       */
      activateExplorer: PropTypes.func.isRequired,
      /**
       * explorer title
       */
      title: PropTypes.string.isRequired,
      /**
       * Is explorer enabled boolean
       */
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  /**
   * Current active explorer id
   */
  activeExplorer: PropTypes.string
};

export default ConditionNavigation;
