import { Vector3, Matrix4, Raycaster } from 'three';
import { get } from 'lodash';
import { cacheManager, cacheKeys } from '../../cache-manager';
import { default as logger } from '../../logger';
import { get_cam_to_abs_tx, lin_mult_forceinline, getCamToUiTx } from './utils/threeUtils';
import const_params from './const_params';
import { toolsEvents } from '../../event-bus/supportedKeys';
import Rect from './classes/rect';

const { Z_INDEX_CENTER_OF_ROTATION_OFFSET, max_distance_to_selected_pt_mm } = const_params;

const pointInsideSurface = new Vector3();

const { EVENT_ORIGINS } = toolsEvents;

let selected_intersect_point = null;

export const has_data = (obj) => {
  return obj && Object.keys(obj).length > 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const logged_assert = (condition, errorMessage) => {
  if (!condition) {
    logger
      .error('error')
      .data({ module: 'lumina-scanner-type.logic', errorMessage })
      .end();
    return;
  }
};

export const initialize_image_meta_data = ({ currentActiveJaw, jawName, meshes, images_meta_data_array }) => {
  const maxNumber = Number.MAX_VALUE;
  const dataJson = cacheManager.get(cacheKeys.DATA_JSON);
  const jawsObject = {
    upper_jaw: dataJson.jaws.upper_jaw,
    lower_jaw: dataJson.jaws.lower_jaw,
  };
  const { scan_to_cam_tx } = dataJson;

  for (let img_idx = 0; img_idx < currentActiveJaw.length; img_idx++) {
    const image_info = jawsObject[jawName].images[img_idx];
    if (!image_info) continue;

    const { timestamp, camera_id, scan_id, local_to_world_tx } = image_info.color;
    const images_meta_data = {
      // 1. Camera points and directions
      camera_pt: new Vector3(0, 0, 0),
      camera_dir: new Vector3(0, 0, 0),

      // 2. Projection of camera to surface
      was_cam_projected: false,
      img_cen_on_surf_pt: new Vector3(0, 0, 0),
      dist_from_cam_to_surf: null,
      cam_to_abs_tx: null,
      is_visible: false,
      // 3. Average height
      average_cam_height: 0,
      scan_role: jawName,
      timestamp: timestamp,
      camera_id: camera_id,
      scan_id: scan_id,
      rect_of_image: new Rect({
        x_min: 0.5,
        x_max: 959.5,
        y_min: 0.5,
        y_max: 539.5,
      }),
    };

    images_meta_data.cam_to_abs_tx = get_cam_to_abs_tx(
      new Matrix4().fromArray(JSON.parse(scan_to_cam_tx)),
      new Matrix4().fromArray(JSON.parse(local_to_world_tx))
    );

    // 1. Camera points and directions
    images_meta_data.camera_pt = new Vector3(0, 0, 0).applyMatrix4(images_meta_data.cam_to_abs_tx);
    images_meta_data.camera_dir = lin_mult_forceinline(new Vector3(0, 0, 1), images_meta_data.cam_to_abs_tx);

    // 2. Projection of camera to surface
    const intersect = getRayIntersectCameraToModelSurface(
      images_meta_data.camera_pt,
      images_meta_data.camera_dir,
      meshes
    );

    if (!intersect) {
      images_meta_data.was_cam_projected = false;
      images_meta_data.img_cen_on_surf_pt = images_meta_data.camera_pt;
      images_meta_data.dist_from_cam_to_surf = maxNumber;
    } else {
      images_meta_data.was_cam_projected = true;
      images_meta_data.img_cen_on_surf_pt = new Vector3().addVectors(
        images_meta_data.camera_pt,
        images_meta_data.camera_dir.multiplyScalar(intersect.distance)
      );
      images_meta_data.dist_from_cam_to_surf = intersect.distance;
    }
    images_meta_data_array.push(images_meta_data);
  }

  // 3. Average height
  const average_cam_height =
    images_meta_data_array.reduce((acc, imageData) => {
      if (imageData.dist_from_cam_to_surf !== maxNumber) {
        acc += imageData.dist_from_cam_to_surf;
      }
      return acc;
    }, 0) / images_meta_data_array.length;
  if (!isNaN(average_cam_height)) {
    images_meta_data_array.map((entry) => (entry.average_cam_height = average_cam_height));
  }

  return images_meta_data_array;
};

export const get_2d_point_in_image_px = (intersect, photoObj) => {
  let screenPoint = {};
  const point = get(intersect, 'point');

  ['niri', 'color'].forEach((key) => {
    const item = get(photoObj, key) || {};
    const worldToCam = item.worldToCamMatrix;
    if (!worldToCam) {
      return;
    }
    screenPoint = point.clone();
    screenPoint.applyMatrix4(worldToCam);
    screenPoint.set(screenPoint.x / screenPoint.z, screenPoint.y / screenPoint.z, 0);
  });
  return screenPoint;
};

export const getRayIntersectCameraToModelSurface = (origin, direction, meshes) => {
  const rayCaster = new Raycaster(origin, direction.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;
  const intersects = rayCaster.intersectObjects(meshes);

  if (intersects && intersects.length > 0) {
    const intersect = intersects[0];
    intersect.point.z -= Z_INDEX_CENTER_OF_ROTATION_OFFSET;
    return intersect;
  }

  return null;
};

export const getIsProjectedPointOnSurface = (point, pointInsideSurface, meshes) => {
  const screenPoint = point.clone();
  const rayCaster = new Raycaster(pointInsideSurface, screenPoint.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;

  const intersectsFromInsideModelToSurface = rayCaster.intersectObjects(meshes);
  let distance = Number.MAX_VALUE;
  if (intersectsFromInsideModelToSurface && intersectsFromInsideModelToSurface.length > 0) {
    distance = intersectsFromInsideModelToSurface.distance;
  }
  if (distance > max_distance_to_selected_pt_mm) {
    return point;
  } else {
    return pointInsideSurface;
  }
};

export const calculateIntersectPointForImageSelection = (eventOrigin, intersectPoint, ray, meshes) => {
  function calculatePointInsideSurface() {
    pointInsideSurface
      .subVectors(intersectPoint, ray.origin)
      .multiplyScalar(1 + 5 / pointInsideSurface.length())
      .add(ray.origin);

    selected_intersect_point = pointInsideSurface;
  }

  if (selected_intersect_point) {
    if (eventOrigin === EVENT_ORIGINS.LOUPE_DRAG) {
      calculatePointInsideSurface();
      return intersectPoint;
    } else if (eventOrigin === EVENT_ORIGINS.MODEL_ROTATION) {
      return getIsProjectedPointOnSurface(intersectPoint, pointInsideSurface, meshes);
    } else {
      logged_assert(eventOrigin && Object.values(EVENT_ORIGINS).includes(eventOrigin), 'Invalid eventOrigin');
      return null;
    }
  } else {
    calculatePointInsideSurface();
    return intersectPoint;
  }
};

export const onImageSelectedUpdateRotation = (selected_image, camMatrixWorldInverse, luminaImageMetaData) => {
  // 1. Handle invalid_image_index
  const { img_idx, image, p2 } = selected_image;
  const imageMetadata = luminaImageMetaData[img_idx];
  const { cam_to_abs_tx } = imageMetadata;

  // 2. Calculate rotation angle and set image cache
  if (img_idx !== -1) {
    const dataJson = cacheManager.get(cacheKeys.DATA_JSON);
    const camera_to_pixel = new Matrix4().fromArray(JSON.parse(dataJson.camera_to_pixel));
    const cam_to_ui_tx = getCamToUiTx(camMatrixWorldInverse, cam_to_abs_tx);
    const rotation = calculateRotationAngle(cam_to_ui_tx);

    image.rotation = rotation || 0;
    image.selected_pt_on_image = p2;
    image.originalImageSize = { width: camera_to_pixel.elements[2] * 2, height: camera_to_pixel.elements[5] * 2 };
  }

  return image;
};

export const calculateRotationAngle = (cam_to_ui_tx) => {
  let rotation_angle = 0;
  let rotation_PI_axis = null;

  const degreValues = Object.freeze({
    e_0_deg: 0,
    e_90_deg: 90,
    e_180_deg: 180,
    e_270_deg: 270,
  });
  const dataJson = cacheManager.get(cacheKeys.DATA_JSON);
  const scan_to_cam_tx = new Matrix4().fromArray(JSON.parse(dataJson.scan_to_cam_tx));

  // TODO remove the condition when we will get the correct scan_to_cam_tx from NG scanner (should be identity matrix)
  if (scan_to_cam_tx.elements[0] === new Matrix4().elements[0]) {
    rotation_PI_axis = new Matrix4().makeRotationX(Math.PI);
  } else {
    rotation_PI_axis = new Matrix4().makeRotationY(Math.PI);
  }

  const cam_to_ui_tx_x_rotated = new Matrix4().multiplyMatrices(rotation_PI_axis, cam_to_ui_tx);

  const { elements } = new Matrix4().copy(cam_to_ui_tx_x_rotated).transpose();

  let x_axis_projection = new Vector3(elements[0], elements[4], 0);

  x_axis_projection = x_axis_projection.divideScalar(x_axis_projection.length());

  const projection_x = x_axis_projection.x;
  const projection_y = x_axis_projection.y;

  if (Math.abs(projection_x) > Math.abs(projection_y)) {
    rotation_angle = projection_x < 0 ? degreValues.e_180_deg : degreValues.e_0_deg;
  } else {
    rotation_angle = projection_y < 0 ? degreValues.e_270_deg : degreValues.e_90_deg;
  }
  return rotation_angle;
};
