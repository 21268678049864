export const menuTypes360 = {
  CIRCULAR: 'circular',
  CONDITION_NAVIGATION: 'condition_navigation',
  NONE: 'none'
};

export const explorers = {
  CARIES: 'caries',
  GUMS: 'gums',
  TOOTH_WEAR: 'toothWear',
  ALIGNMENT: 'alignment',
  EXPLORER: 'explorer'
};
