import apiMap, { apiMapKeys } from '../../api-service/apiMap';
import { cacheManager, cacheKeys } from '../../cache-manager/cache-manager';
import { ViewerSoftwareOptions, SoftwareOptionsForCompany } from '../../constants/enums.constants';
import { Environments } from '../../constants/environment.constants';
import { getBffUrl, getScannerUrl } from '../../api-service/apiService-helper';
import { utils } from '../../utils';
import localhostMock from './localhost-mock';
import { handleRequest } from '../../api-service/apiService-helper';
import { eventBus } from '../../event-bus';
import { globalEventsKeys } from '../../event-bus/supportedKeys';
import { logToTimber, logToTimberBI, biMethods } from '../../timberLogger';
import logger from '../../logger';

const serviceName = 'app-settings-service';

const { getOrderId, getCompanyId, getRxGuid, isStandaloneMode } = utils;

const logToTimberAndBI = (error) => {
  logger
    .info('Network')
    .to(['analytics', 'host'])
    .data({ error, module: serviceName })
    .end();

  logToTimber({
    timberData: {
      action: `${serviceName} fetch resources`,
      module: serviceName,
      type: 'object',
      actor: 'System',
      value: {},
    },
  });

  logToTimberBI(
    biMethods.errorReportBiLog({
      object: serviceName,
      code: `${serviceName} error to fetch resources`,
      description: error.message,
      severity: 'Fetch settings failed',
    })
  );
};

const executePollRequest = async (pollFunction) => {
  let interval = 0;
  const pollRequestInterval = setInterval(async () => {
    interval += 1;
    const result = await pollFunction();

    if (result) {
      clearInterval(pollRequestInterval);
      interval = 0;
    } else if (interval >= 12 && !result) {
      clearInterval(pollRequestInterval);
      interval = 0;
      eventBus.raiseEvent(globalEventsKeys.POLL_REQUEST_RESPONSE_ERROR);
    }
  }, 5000);
};

export const requestFunctions = {
  getFeaturesToggleSettings: {
    function: async () => {
      return await handleRequest({
        selector: apiMapKeys.getFeaturesToggleSettings,
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
  },
  getCompanySoftwareOptions: {
    function: async () => {
      const env = utils.getEnv();
      const requestParams = {
        selector: apiMapKeys.getCompanySoftwareOptions,
        ...(env === Environments.EUP
          ? {
              queryParams: {
                companyId: getCompanyId(),
              },
            }
          : {}),
        module: serviceName,
      };

      return await handleRequest(requestParams);
    },
    isEnabledFor360Only: false,
  },
  getPatientOrdersForComparison: {
    function: async () => {
      const orderId = getOrderId();
      const rxGuid = getRxGuid();
      const params = rxGuid ? { rxGuid } : { orderId };

      return await handleRequest({
        selector: apiMapKeys.getPatientOrdersForComparison,
        queryParams: {
          ...params,
        },
        module: serviceName,
      });
    },
    isEnabledFor360Only: true,
  },
  areScannerSoftwareOptionsAvailable: {
    function: async () => {
      const env = utils.getEnv();
      const queryParams = `${Object.values(ViewerSoftwareOptions)
        .concat(Object.values(SoftwareOptionsForCompany))
        .map((swo) => `softwareOptions=${swo}`)
        .join('&')}&companyId=${getCompanyId()}`;

      const { path, pathScanner } = apiMap[apiMapKeys.areScannerSoftwareOptionsAvailable];
      const pathParam = env === Environments.SCANNER ? pathScanner : path;
      const host = env === Environments.SCANNER ? getScannerUrl() : getBffUrl();
      const requestUrl = `${host}/${pathParam}?${queryParams}`;

      return await handleRequest({
        url: requestUrl,
        module: serviceName,
      });
    },
    isEnabledFor360Only: false,
  },
};

export const requestSingle = async (functionName) => {
  const response = await Promise.resolve(requestFunctions[functionName].function());
  if (response.status >= 200 && response.status < 300) {
    const fromPromiseResult = await response.json();
    const appSettingsCache = {};
    appSettingsCache[functionName] = fromPromiseResult;
    cacheManager.set(cacheKeys.APP_SETTINGS, appSettingsCache);

    return fromPromiseResult;
  }

  return new Error(`requestSingle function failed to fetch ${functionName} response status code: ${response.status}`);
};

export const getAllSettings = async () => {
  const isLocalhostDev = isStandaloneMode();
  if (isLocalhostDev) {
    cacheManager.set(cacheKeys.APP_SETTINGS, localhostMock);
    return localhostMock;
  }

  try {
    const promiseArray = [];
    const is360hub = utils.getIs360HubEnabled();

    Object.entries(requestFunctions).forEach(([key, requestFunction]) => {
      const isFunctionEnabledForContext =
        (is360hub && requestFunction.isEnabledFor360Only) || !requestFunction.isEnabledFor360Only;
      isFunctionEnabledForContext && promiseArray.push({ [key]: requestFunction.function() });
    });

    const createResultObject = async () => {
      const resolvedPromises = await Promise.all(promiseArray.map((promise) => promise[Object.keys(promise)[0]]));
      const filteredPromises = resolvedPromises.filter((response) => response.status >= 200 && response.status < 300);
      const badResponse = resolvedPromises.filter((response) => response.status > 300);
      badResponse.forEach((response) => {
        const indexToRemove = resolvedPromises.indexOf(response);
        promiseArray.splice(indexToRemove, 1);
      });

      const result = {};
      for (let index = 0; index < filteredPromises.length; index++) {
        try {
          const json = await filteredPromises[index].json();
          result[Object.keys(promiseArray[index])[0]] = json;
        } catch (error) {
          logToTimberAndBI(error);
          return Promise.reject(error);
        }
      }
      return result;
    };

    const fromPromiseResults = await createResultObject();

    eventBus.subscribeToEvent(globalEventsKeys.RAISE_POLL_REQUEST, async (pollRequestFunction) => {
      await executePollRequest(pollRequestFunction);
    });

    const appSettingsCache = cacheManager.get(cacheKeys.APP_SETTINGS) || {};
    const result = { ...appSettingsCache, ...fromPromiseResults };
    cacheManager.set(cacheKeys.APP_SETTINGS, result);
    return Promise.resolve(result);
  } catch (error) {
    const { errorMessage, selector, url } = error || {};
    error.message = errorMessage && (selector || url) ? `${errorMessage}, ${selector} ${url}` : error.message;
    logToTimberAndBI(error);
    return Promise.reject(error);
  }
};

export default {
  getAllSettings,
  requestSingle,
};
