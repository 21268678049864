import { Vector2 } from 'three';

class Rect {
  constructor({ x_min, x_max, y_min, y_max }) {
    this.x_min = x_min;
    this.x_max = x_max;
    this.y_min = y_min;
    this.y_max = y_max;
  }

  includesRect(rect) {
    const { x_min, x_max, y_min, y_max } = rect;
    return this.x_min >= x_min && this.x_max <= x_max && this.y_min >= y_min && this.y_max <= y_max;
  }

  includesPoint(point) {
    const { x, y } = point;
    return x >= this.x_min && x <= this.x_max && y >= this.y_min && y <= this.y_max;
  }

  corner_min() {
    return new Vector2(this.x_min, this.y_min);
  }

  corner_max() {
    return new Vector2(this.x_max, this.y_max);
  }

  is_empty() {
    return this.x_min >= this.x_max || this.y_min >= this.y_max;
  }

  x_size() {
    return this.x_max - this.x_min;
  }

  y_size() {
    return this.y_max - this.y_min;
  }

  area() {
    const width = this.x_size();
    const height = this.y_size();
    return width * height;
  }

  center() {
    const width = this.x_size() / 2;
    const height = this.y_size() / 2;
    const centerX = this.x_min + width;
    const centerY = this.y_min + height;
    return new Vector2(centerX, centerY);
  }

  clear() {
    this.x_min = 0;
    this.x_max = 0;
    this.y_min = 0;
    this.y_max = 0;
  }
}

export default Rect;
