import React, { useEffect, Fragment, useState, useMemo, useRef } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';

import { noop } from 'lodash';
import { Canvas, useThree } from 'react-three-fiber';

import {
  eventBus,
  getOpacityForCameraDirection,
  globalEventsKeys,
  settingsManager,
  cacheManager,
  cacheKeys,
  utils,
  syncModelCompareCameras,
} from '@web-3d-tool/shared-logic';
import { menuTypes360 } from '@web-3d-tool/shared-logic/src/constants/menuTypes360.constants';
import { ZoomConstants } from '@web-3d-tool/shared-logic/src/constants/camera.constants';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import FPSStats from 'react-fps-stats';
import { Camera, Controls, useCamera } from './Camera';
import Scene from './Scene';

import stylesViewer from './Renderer.module.css';
import styles360 from './Renderer360.module.css';

const Renderer = (props) => {
  const {
    cameraProps,
    meshes,
    panoramaMesh,
    ignoreModels,
    getThreeJSObjects,
    onCameraMove,
    onCameraStopMoving,
    geometries,
    onTap2Fingers,
    onDoubletap2Fingers,
    id,
    onMount,
    resetCameraRotationOnUpdate,
    menuType360,
    isModelCompareActive,
    isModelSynced,
    numberOfItems,
    isSplittedViewWithSidePluginActive,
    imageFrameDimentions,
    isModelCompareInDifferentMode,
  } = props;
  const isModelCompareActiveAndSynced = useRef(false);
  const is360 = utils.getIs360HubEnabled();
  const styles = is360 ? styles360 : stylesViewer;
  const isDebugEnabled = useMemo(() => settingsManager.getConfigValue(configValues.debug) === 'true', []);
  const isOnLanding = useMemo(() => menuType360 === menuTypes360.CIRCULAR, [menuType360]);
  const zoomParameter = useMemo(() => {
    if (isOnLanding) {
      return ZoomConstants.LANDING_PAGE_MODEL_ZOOM;
    } else {
      return is360 ? ZoomConstants.DEFAULT_MODEL_ZOOM_LAYOUT360 : ZoomConstants.DEFAULT_MODEL_ZOOM;
    }
  }, [is360, isOnLanding]);

  const {
    camera,
    cameraControls,
    cameraRef,
    cameraControlsRef,
    resetCameraPosition,
    zoomCameraTo,
    setStaticMode,
  } = useCamera(cameraProps.position, cameraProps.up, zoomParameter);

  const [panoramaObj, setPanoramaMesh] = useState(panoramaMesh);

  const setMeshOpacity = (camera, mesh) => {
    setPanoramaMesh((currentMesh) => {
      if (camera) {
        const opacity = getOpacityForCameraDirection(camera);
        const meshToupdate = currentMesh || mesh;
        meshToupdate && meshToupdate.material && (meshToupdate.material.opacity = opacity);
        return meshToupdate;
      }
    });
  };

  useEffect(() => {
    panoramaMesh ? setMeshOpacity(camera, panoramaMesh) : setPanoramaMesh(panoramaMesh);
  }, [panoramaMesh, camera]);

  useEffect(() => {
    isOnLanding && eventBus.raiseEvent(globalEventsKeys.RESET_RENDERING_STAGE);
    resetCameraPosition(meshes);
    setStaticMode(isOnLanding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnLanding, setStaticMode]);

  useEffect(() => {
    const { currentState } = isModelCompareActiveAndSynced.current || {
      currentState: isModelCompareActive && isModelSynced,
    };
    isModelCompareActiveAndSynced.current = {
      prevState: currentState,
      currentState: isModelCompareActive && isModelSynced,
    };
  }, [isModelCompareActive, isModelSynced]);

  if (!meshes) return null;

  const filteredMeshes = meshes.filter(({ modelName }) => !ignoreModels.includes(modelName));

  const onSceneMount = () => {
    zoomCameraTo(geometries);
    onMount();
    setStaticMode(isOnLanding);
    if (isModelCompareActive && isModelCompareActiveAndSynced.current.currentState && isModelCompareInDifferentMode) {
      syncModelCompareCameras({
        currentActiveCamera: camera,
        isModelCompareInDifferentMode,
        isOnMount: true,
        isModelCompareActiveAndSynced,
      });
    }
  };

  const ImperativeComp = ({ getThreeJSObjects }) => {
    // this component is a shadow component that enable us to use
    // useThree() to be able to pull out the imperative objects and send it to

    const controller = new AbortController();
    const { scene, size, canvas, gl } = useThree();

    const updateCameraZoomOnWidthChange = () => {
      let scenesDimensions = cacheManager.get(cacheKeys.SCENES_DIMENSIONS);
      if (!scenesDimensions) {
        scenesDimensions = { [scene.uuid]: size };
        cacheManager.set(cacheKeys.SCENES_DIMENSIONS, scenesDimensions);
        return;
      }
      const prevSceneWidth = scenesDimensions[scene.uuid]?.width;
      if (prevSceneWidth !== size.width) {
        scenesDimensions[scene.uuid] = size;
        cacheManager.set(cacheKeys.SCENES_DIMENSIONS, scenesDimensions);
        zoomCameraTo(geometries);
      }
    };
    useEffect(() => {
      const [camera, group] = scene.children;
      getThreeJSObjects && getThreeJSObjects({ scene, camera, group, size, canvas, controls: cameraControls });

      const cachedCamera = cacheManager.get(cacheKeys.CAMERA_MODEL_COMPARE) || {};
      if (Object.keys(cachedCamera).length < 2 && !cachedCamera[camera.uuid]) {
        const { uuid } = camera;
        cachedCamera[uuid] = { camera, scene, gl, timestamp: new Date().getTime() };
        cacheManager.set(cacheKeys.CAMERA_MODEL_COMPARE, cachedCamera);
      }

      const setWindowSize = () => {
        if (isSplittedViewWithSidePluginActive && imageFrameDimentions) {
          const newWidth =
            (window.innerWidth - imageFrameDimentions.width + imageFrameDimentions.drawerWidth) / numberOfItems;
          gl.setSize(newWidth, window.innerHeight);
        } else if (numberOfItems === 2) {
          const newWidth = window.innerWidth / numberOfItems;
          gl.setSize(newWidth, window.innerHeight);
        } else {
          gl.setSize(window.innerWidth, window.innerHeight);
        }
        gl.render(scene, camera);
      };

      const setCurrentSize = () => {
        const currentSize = gl.getSize(new THREE.Vector2());
        gl.setSize(currentSize.width, currentSize.height);
        gl.render(scene, camera);
      };

      window.addEventListener('resize', setWindowSize, { signal: controller.signal });
      document.addEventListener('visibilitychange', setCurrentSize, { signal: controller.signal });
      updateCameraZoomOnWidthChange();

      return () => {
        controller.abort();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getThreeJSObjects, canvas, scene, scene.children, size, cameraControls]);

    return <Fragment />;
  };

  const handleChangeControls = (target) => {
    const { isZooming, isPanning } = target.activeManipulation || {};
    const isWheelZoom = !!(isZooming && !isPanning);
    if (isModelCompareActiveAndSynced.current.currentState) {
      isWheelZoom
        ? setTimeout(() => {
            // We use setTimeout because we need the camera to
            // complete it's manipulation before re-positioning the loupe.
            syncModelCompareCameras({
              currentActiveCamera: target.camera,
              isModelCompareInDifferentMode,
              isModelCompareActiveAndSynced,
              isWheelZoom,
            });
          }, 50)
        : syncModelCompareCameras({
            currentActiveCamera: target.camera,
            isModelCompareInDifferentMode,
            isModelCompareActiveAndSynced,
          });
    }
  };
  return (
    <>
      <Canvas
        invalidateFrameloop
        pixelRatio={window.devicePixelRatio}
        className={isOnLanding ? styles.rendererLanding : styles.renderer}
        gl={{ preserveDrawingBuffer: true }}
        id={id}
      >
        <ImperativeComp getThreeJSObjects={getThreeJSObjects} />

        <Camera
          ref={cameraRef}
          near={1}
          far={1500}
          position={cameraProps.position}
          up={cameraProps.up}
          zoom={zoomParameter}
          resetCameraRotationOnUpdate={resetCameraRotationOnUpdate}
          onResetCameraRotation={() => zoomCameraTo(geometries)}
        >
          <Controls
            ref={cameraControlsRef}
            enabled={true}
            dynamicDampingFactor={0.3}
            staticMoving={true}
            handleTap2Fingers={(controls) => {
              zoomCameraTo(meshes);
              const target = controls;
              onCameraMove({ target });
              onCameraStopMoving({ target });
              onTap2Fingers(controls);
              handleChangeControls(target);
            }}
            handleDoubletap2Fingers={(controls) => {
              resetCameraPosition(meshes);
              const target = controls;
              onCameraMove({ target });
              onCameraStopMoving({ target });
              onDoubletap2Fingers(controls);
              handleChangeControls(target);
            }}
            onChange={({ target }) => {
              onCameraMove({ target });
              setMeshOpacity(target.camera, panoramaObj);
              handleChangeControls(target);
            }}
            onEnd={onCameraStopMoving}
            isStaticMode={isOnLanding}
          />
        </Camera>

        {camera && cameraControls && (
          <>
            <Scene meshes={filteredMeshes} onMount={onSceneMount} />
            {panoramaObj && (
              <scene name="panorama">
                <mesh name="panoramaMesh" {...panoramaObj} />
              </scene>
            )}
          </>
        )}
      </Canvas>
      {isDebugEnabled && <FPSStats />}
    </>
  );
};

Renderer.propTypes = {
  /**
   * Camera options
   */
  cameraProps: PropTypes.shape({
    /**
     * Camera frustum near plane
     */
    near: PropTypes.number,
    /**
     * Camera frustum far plane
     */
    far: PropTypes.number,
    /**
     * A Vector3 representing the camera's local position
     */
    position: PropTypes.arrayOf(PropTypes.number),
    /**
     * This is used by the lookAt method
     */
    up: PropTypes.arrayOf(PropTypes.number),
  }),
  /**
   * Meshes to render
   */
  meshes: PropTypes.arrayOf(PropTypes.number),
  /**
   * Textures
   */
  textures: PropTypes.arrayOf(PropTypes.object),
  ignoreModels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Enable to get the three js imperative objects
   * e.g: scene, camera, group, etc.
   */
  getThreeJSObjects: PropTypes.func,
  onCameraMove: PropTypes.func,
  onCameraStopMoving: PropTypes.func,
  onTap2Fingers: PropTypes.func,
  onDoubletap2Fingers: PropTypes.func,
};

Renderer.defaultProps = {
  ignoreModels: [],
  onCameraMove: noop,
  onCameraStopMoving: noop,
};

export default Renderer;
