import * as configuration from '../constants/configurationValues.constants';
import * as scannerAssets_22B from './scanner-assets-22B/scanner-assets';
import * as scannerAssets_23A from './scanner-assets-23A/scanner-assets';
import { utils } from '../utils';
import scannerVersions from './scanner-versions.json';

const scannerAssetsVersions = (() => {
  const keysObject = {};
  Object.keys(scannerVersions).forEach(key => {
    keysObject[key] = key;
  });
  return keysObject;
})();

const compareVersion = ({ currentVersion, versions1, versions2 }) => {
  if (!currentVersion) return false;

  const version1 = versions1.find(v1 => v1[0] === currentVersion[0]);
  const version2 = versions2 && versions2.find(v2 => v2[0] === currentVersion[0]);

  const compare = (v1, v2) => {
    return v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' });
  };

  if (version2) {
    const currentVersusV1 = compare(currentVersion, version1);
    const currentVersusV2 = compare(currentVersion, version2);
    return currentVersusV1 < 0 && currentVersusV2 > 0;
  } else {
    return compare(currentVersion, version1) > -1;
  }
};

const getScannerVersion = () => {
  const currentScannerVersion = utils.getUrlParam({ param: configuration.ScannerPackageVersion });
  const { v22B, v23A } = scannerVersions;
  switch (true) {
    case compareVersion({ currentVersion: currentScannerVersion, versions1: v23A }):
      return scannerAssetsVersions.v23A;
    case compareVersion({ currentVersion: currentScannerVersion, versions1: v22B }):
      return scannerAssetsVersions.v22B;
    default:
      return scannerAssetsVersions.v22B;
  }
};

const getScannerAssets = () => {
  const scannerAssetsVersion = getScannerVersion();
  switch (scannerAssetsVersion) {
    case scannerAssetsVersions.v23A:
      return scannerAssets_23A;
    case scannerAssetsVersions.v22B:
      return scannerAssets_22B;
    default:
      return scannerAssets_22B;
  }
};

export const scannerVersionManager = {
  getScannerAssets,
  getScannerVersion,
  scannerAssetsVersions
};
