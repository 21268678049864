// This file is for developers use only!
// With this file you can control and debug the Lumina algorithm.
import { PLYExporter } from 'three/examples/jsm/exporters/PLYExporter.js';
import * as THREE from 'three';
import { cacheManager, cacheKeys } from '../../../cache-manager/cache-manager';
import { eventBus, globalEventsKeys } from '../../../event-bus';

const GLOBAL_THREE_OBJECTS = { threeObjects: null };
const helper = new THREE.Box3Helper(new THREE.Box3(), 'black');
let gridHelper = new THREE.GridHelper(0, 0);
let images_meta_data = [];
let lineGroup = new THREE.Group();
let activeJawKey = 'lower_jaw';
gridHelper.name = 'myGridHelper';
lineGroup.name = 'PerspectiveCameraProjectionGroup';

/*
  [0]
  Visualizing the projected points from the wand to the surface of the Model (JAW).
  The red dots represents the intersection points of rays casted from the wand camera to the Model surface.
  The blue dots represent the rays that casted from the wand camera but not intersected with the surface. 
*/
const debug_camera_projection_points_on_models_surface = () => {
  const { scene } = GLOBAL_THREE_OBJECTS.threeObjects;

  for (const image_metadata of images_meta_data) {
    const { was_cam_projected, img_cen_on_surf_pt, camera_pt } = image_metadata;
    const sphereGeometry = new THREE.SphereGeometry(0.1, 8, 8); // Create a small sphere geometry
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: was_cam_projected ? 0xff0000 : 0x0000ff }); // Set the material color to red
    const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial); // Create a mesh object with the sphere geometry and material
    const sphere = sphereMesh.clone(); // Create a clone of the sphere mesh to avoid modifying the original

    if (was_cam_projected) {
      sphere.position.copy(new THREE.Vector3(img_cen_on_surf_pt.x, img_cen_on_surf_pt.y, img_cen_on_surf_pt.z + 0.5));
    } else {
      sphere.position.copy(camera_pt);
    }
    scene.add(sphere);
  }
};

/*
  [1]
  Visualizing the projected rays from our perspective camera to the red dots represents
  the intersection points of rays casted from the wand camera to the Model surface.
  (Ray casted from our perspective camera to all the red dots from 'debug_camera_projection_points_on_models_surface' function).
  This debug represents ONLY the images we see from our screen side when we are looking at the model.
*/
const debug_calculate_perspective_camera_projection = () => {
  eventBus.subscribeToEvent(globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE, () => {
    const { scene, camera } = GLOBAL_THREE_OBJECTS.threeObjects;
    const cameraPosition = new THREE.Vector3();
    camera.getWorldPosition(cameraPosition);
    const groupToRemove = scene.getObjectByName('PerspectiveCameraProjectionGroup');
    if (groupToRemove) {
      scene.remove(groupToRemove);
      lineGroup = new THREE.Group();
      lineGroup.name = 'PerspectiveCameraProjectionGroup';
    }

    for (let i = 0; i < images_meta_data.length; i++) {
      const { is_visible } = images_meta_data[i];
      if (is_visible) {
        const material = new THREE.LineBasicMaterial({
          color: 0x00ff00,
          opacity: 0.5, // Set opacity to 50%
          transparent: true, // Enable transparency
        });

        const geometry = new THREE.BufferGeometry().setFromPoints([
          images_meta_data[i].img_cen_on_surf_pt, // Start point of the line
          cameraPosition, // End point of the line
        ]);
        const line = new THREE.Line(geometry, material);
        lineGroup.add(line);
      }
    }
    scene.add(lineGroup);
  });
};

/*
  [2]
  Visualizing the bounding box that is being calculated when we are rotating the model.
  This bounding box is relative to our world coordinates and not to model coordinates, because of this the bounding box
  is changing every time we moving the model to fit the model size and position when we see it on our screen. 
*/
const debug_model_bounding_box = () => {
  eventBus.subscribeToEvent(
    globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE,
    ({ surface_projection_segmentation_grid }) => {
      const { scene } = GLOBAL_THREE_OBJECTS.threeObjects;
      const { bbox } = surface_projection_segmentation_grid;
      helper.box = bbox || new THREE.Box3();
      scene.add(helper);
      console.log('bbox', bbox);
    }
  );
};

/*
  [3]
  Visualizing the calculation of grid in the console as |1,0| when 1 is image candidate is set and 0 is when no candidate is 
  calculated for this grid cell.
  If the algorithm is working as expected we should see a shape of our current jaw in the console in respective to the jaw position.
  like this:
                        0000000000000000000000000000000000000000000000000000000000000000000000
                        0000000000000000000000000000000000000000000000000000000000000000000000
                        0000000000000000000000000000000000000000000000000000000111111100000000
                        0000000000000000000000000000000000000000000000000000011111111111000000
                        0000000000000000000000000000000000000000000000000001111111111111111111
                        0000000000000000000000000000000000000000000000000011111111111111111111
                        1110000000000000000000000000000000000000000000000011111111111111111111
                        1111110000001111000000000000000000000000000000000111111111111111111111
                        1111111101111111111000000000000000000000000000000111111111111111111111
                        1111111111111111111100000000000000000000000000001111111111111111111111
                        1111111111111111111110000000000000000000000000001111111111111111111111
                        1111111111111111111111000000000000000000000000001111111111111111111111
                        1111111111111111111111100000000000000000000000011111111111111111111111
                        1111111111111111111111100000000000000000000000111111111111111111111111
                        1111111111111111111111110000000000000000000001111111111111111111111111
                        1111111111111111111111110000000000000000000001111111111111111111111111
                        1111111111111111111111110000000000000000000001111111111111111111111111
                        1111111111111111111111110000000000000000000011111111111111111111111111
                        1111111111111111111111110000000000000000000011111111111111111111111111
                        1111111111111111111111110000000000000000000011111111111111111111111111
                        1111111111111111111111110000000000000000000011111111111111111111111111
                        1111111111111111111111111000000000000000000111111111111111111111111111
                        1111111111111111111111111000000000000000000111111111111111111111111111
                        1111111111111111111111111000000000000000000111111111111111111111111111
                        1111111111111111111111111000000000000000001111111111111111111111111111
                        1111111111111111111111111000000000000000001111111111111111111111111111
                        1111111111111111111111111100000000000000001111111111111111111111111111
                        1111111111111111111111111100000000000000001111111111111111111111111111
                        1111111111111111111111111100000000000000011111111111111111111111111111
                        1111111111111111111111111110000000000000011111111111111111111111111111
                        1111111111111111111111111111000000000000111111111111111111111111111111
                        1111111111111111111111111111000000000000111111111111111111111111111111
                        1111111111111111111111111111100000000001111111111111111111111111111111
                        1111111111111111111111111111100000000001111111111111111111111111111111
                        1111111111111111111111111111110000000011111111111111111111111111111111
                        1111111111111111111111111111110000000111111111111111111111111111111111
                        1111111111111111111111111111111000001111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111111
                        1111111111111111111111111111111111111111111111111111111111111111111110
                        0111111111111111111111111111111111111111111111111111111111111111111100
                        0011111111111111111111111111111111111111111111111111111111111111111100
                        0001111111111111111111111111111111111111111111111111111111111111111000
                        0001111111111111111111111111111111111111111111111111111111111111111000
                        0000111111111111111111111111111111111111111111111111111111111111111000
                        0000011111111111111111111111111111111111111111111111111111111111110000
                        0000001111111111111111111111111111111111111111111111111111111111100000
                        0000000111111111111111111111111111111111111111111111111111111111100000
                        0000000011111111111111111111111111111111111111111111111111111111000000
                        0000000011111111111111111111111111111111111111111111111111111110000000
                        0000000001111111111111111111111111111111111111111111111111111110000000
                        0000000001111111111111111111111111111111111111111111111111111100000000
                        0000000000111111111111111111111111111111111111111111111111111000000000
                        0000000000011111111111111111111111111111111111111111111111110000000000
                        0000000000001111111111111111111111111111111111111111111111100000000000
*/
const debug_candidate_grid_calculation = () => {
  eventBus.subscribeToEvent(globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE, ({ candidate_grid }) => {
    let rows = '';
    const color = '\x1b[31m'; // Red color

    // for (let i = candidate_grid.rows - 1; i >= 0; i--) {
    //   for (let j = 0; j < candidate_grid.cols; j++) {
    //     const x = candidate_grid.get_cell_candidate(i, j);
    //     rows += ` ${x ? x.candidate_id : -1}`;
    //   }
    //   rows += ` ${i}\n`;
    // }
    for (let i = 0; i < candidate_grid.rows; i++) {
      for (let j = 0; j < candidate_grid.cols; j++) {
        const x = candidate_grid.get_cell_candidate(i, j);
        rows += ` ${x ? x.candidate_id : -1}`;
      }
      rows += `\n`;
    }
    console.log(rows.replace(/1/g, `${color}1\x1b[0m`));
  });
};

/*
  [4]
  Visualizing the XYZ axes in world coordinates on the model.
*/
const debug_add_xyz_axes_at_world_coordinates = () => {
  const { scene } = GLOBAL_THREE_OBJECTS.threeObjects;
  const axesHelper = new THREE.AxesHelper(50);
  scene.add(axesHelper);
};

/*
  [5]
  Visualizing the center of the imageFrame and the loupe intersec point on the image.
*/
const debug_image_frame = () => {
  return true;
};

export const currentActiveDebugFunctions = {};

export const exportPLYModel = (object3D) => {
  const exporter = new PLYExporter();
  // export the merged geometry as a PLY file
  var plyData = exporter.parse(object3D);

  // save the PLY file to disk
  var blob = new Blob([plyData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'mesh.ply';
  link.click();
};

/**
 * Calling the debug functions by array index.
 *
 * 0: debug_camera_projection_points_on_models_surface.
 *
 * 1: debug_calculate_perspective_camera_projection.
 *
 * 2: debug_model_bounding_box.
 *
 * 3: debug_candidate_grid_calculation.
 *
 * 4: debug_add_xyz_axes_at_world_coordinates
 *
 * Example for a function call: debug_init([0,2]);
 * @param {Array} initArr - The first number to add.
 * @returns {void} initializes the debug.
 */
export const debug_init = (initArr) => {
  try {
    eventBus.subscribeToEvent(globalEventsKeys.LUMINA_INITIALIZE_COMPLETTE, () => {
      const { lower_jaw, upper_jaw } = cacheManager.get(cacheKeys.THREE_OBJECTS) || {};
      const currentActiveJaw = Object.fromEntries(
        Object.entries({ lower_jaw, upper_jaw }).filter((jawEntry) => !!jawEntry[1])
      );
      const luminaImageMetaData = ['lower_jaw', 'upper_jaw'].map(
        (jawName) => cacheManager.get(cacheKeys.IMAGES_META_DATA)[jawName].images
      );

      activeJawKey = Object.keys(currentActiveJaw)[0];
      GLOBAL_THREE_OBJECTS.threeObjects = { ...(currentActiveJaw[activeJawKey] || {}) };
      images_meta_data = [].concat(...luminaImageMetaData);

      const debugFunctionsArr = [
        debug_camera_projection_points_on_models_surface,
        debug_calculate_perspective_camera_projection,
        debug_model_bounding_box,
        debug_candidate_grid_calculation,
        debug_add_xyz_axes_at_world_coordinates,
        debug_image_frame,
      ];

      for (const index of initArr) {
        currentActiveDebugFunctions[debugFunctionsArr[index].name] = debugFunctionsArr[index];
        debugFunctionsArr[index]();
      }
    });
  } catch (error) {
    return;
  }
};
