/**
 * this component was originally taken from:
 * https://github.com/mrdoob/three.js/blob/r108/examples/jsm/lines
 */

import { LineSegmentsExtended } from './LineSegmentsExtended.js';
import { LineGeometry } from './LineGeometry.js';
import { LineMaterial } from './LineMaterial';

class LineExtended extends LineSegmentsExtended {
  constructor(geometry, material) {
    super(geometry, material);
    this.type = 'LineExtended';

    this.geometry = geometry !== undefined ? geometry : new LineGeometry();
    this.material = material !== undefined ? material : new LineMaterial({ color: Math.random() * 0xffffff });
  }

  copy() {
    return this;
  }
}

LineExtended.prototype.isLineExtended = true;

LineExtended.prototype.constructor = LineExtended;

export { LineExtended };
