import { get, keys } from 'lodash';
import { CatmullRomCurve3, ExtrudeGeometry, LineBasicMaterial, Matrix4, Mesh, Shape, Vector3 } from 'three';

import { EXTRACTED_MODEL, ObjectsKeys } from '../../constants/model.constants';
import { utils } from '../../utils';
import { createMipmapTexture } from './texture';
import { extractFile } from '../../unzip-util';
import CTM from './ctm';
import CTMLoader from './ctm-loader';
import { logToTimber } from '../../timberLogger';
import logger from '../../logger';
import { downloadFile } from '../download-file';
import { Environments } from '../../constants/environment.constants';
import request from '../../api-service/apiService';
import { apiMapKeys } from '../../api-service/apiMap';

const ctmLoader = new CTMLoader();

const getAdaIdFromPrepName = (prepName) => prepName.replace('ada', '');

const parseMarginLineGeometry = (str) => {
  if (str === undefined) return null;

  const lines = str.split('\n');
  if (lines.length <= 0) return null;

  const numOfLines = parseInt(lines[0], 10);
  if (lines.length < numOfLines) return null;

  const pts = [];

  for (let i = 1; i <= numOfLines; i++) {
    const pointsArray = lines[i].split(' ');
    pts.push(new Vector3(Number(pointsArray[0]), Number(pointsArray[1]), Number(pointsArray[2])));
  }

  const curvesPath = new CatmullRomCurve3(pts);
  const extrudeSettings = {
    steps: numOfLines,
    bevelEnabled: false,
    extrudePath: curvesPath,
  };

  const shape = new Shape();
  const length = 0.03;

  shape.moveTo(0, 0);
  shape.lineTo(-length, -length);
  shape.lineTo(length, -length);
  shape.lineTo(length, length);
  shape.lineTo(-length, length);
  shape.lineTo(-length, -length);

  const geometry = new ExtrudeGeometry(shape, extrudeSettings);
  return new Mesh(geometry, new LineBasicMaterial());
};

const setMissingPreps = (metaData) => {
  const obj = {};
  for (let prepName in metaData.missing_preps) obj[prepName] = {};
  return obj;
};

const createOffsetLineGeometryForPrep = async (zippedModel, prepName, metadata, adaId) => {
  if (metadata.preps[prepName].offset_line === undefined || metadata.preps[prepName].offset_line.url === undefined)
    return null;

  const { url } = metadata.preps[prepName].offset_line;
  const str = await zippedModel.file(url).async('string');
  const marginLineGeometry = parseMarginLineGeometry(str);
  const { MarginLine } = ObjectsKeys.Preps[adaId];
  const geometry = await createGeometryFromCTMStream(marginLineGeometry);
  return { [MarginLine]: geometry };
};

const getPreps = async (metadata, zippedModel) => {
  let obj = {};

  if (!metadata.preps) {
    return obj;
  }

  for (let prepName in metadata.preps) {
    const { url } = metadata.preps[prepName];
    if (url === undefined) continue;

    const adaId = getAdaIdFromPrepName(prepName);
    const geometry = await getGeometryFromZippedModel(zippedModel, url);
    const marginLineObj = await createOffsetLineGeometryForPrep(zippedModel, prepName, metadata, adaId);

    obj = { ...obj, [prepName]: geometry, ...marginLineObj };

    const cover = get(metadata, `preps.${prepName}.ditch.cover`);
    if (cover) {
      const ditchCoverName = ObjectsKeys.Preps[adaId].DitchCover;
      const ditchCoverUrl = metadata.preps[prepName].ditch.cover.url;
      const ditchCoverGeometry = await getGeometryFromZippedModel(zippedModel, ditchCoverUrl);

      obj = {
        ...obj,
        [ditchCoverName]: ditchCoverGeometry,
      };
    }

    const ditchOuter = get(metadata, `preps.${prepName}.ditch.outer`);
    if (ditchOuter) {
      const ditchOuterName = ObjectsKeys.Preps[adaId].DitchOuter;
      const ditchOuterUrl = metadata.preps[prepName].ditch.outer.url;
      const ditchOuterGeometry = await getGeometryFromZippedModel(zippedModel, ditchOuterUrl);
      obj = {
        ...obj,
        [ditchOuterName]: ditchOuterGeometry,
      };
    }

    const ditchInner = get(metadata, `preps.${prepName}.ditch.inner`);
    if (ditchInner) {
      const ditchInnerName = ObjectsKeys.Preps[adaId].DitchInner;
      const ditchInnerUrl = metadata.preps[prepName].ditch.inner.url;
      const ditchInnerGeometry = await getGeometryFromZippedModel(zippedModel, ditchInnerUrl);
      obj = {
        ...obj,
        [ditchInnerName]: ditchInnerGeometry,
      };
    }

    const adjacent = get(metadata, `preps.${prepName}.adjacent`);
    if (adjacent) {
      const adjName = ObjectsKeys.Preps[adaId].Adjacent;
      const adjUrl = metadata.preps[prepName].adjacent.url;
      const adjGeometry = await getGeometryFromZippedModel(zippedModel, adjUrl);
      obj = { ...obj, [adjName]: adjGeometry };
    }
  }
  return obj;
};

export const getModelByMetadata = async (metadata, extractedModel, zippedModel) => {
  try {
    const model = { ...extractedModel };
    const missingPreps = setMissingPreps(metadata);
    const jaws = await getJaws(metadata, zippedModel);
    const preps = await getPreps(metadata, zippedModel);
    model.objects = { ...missingPreps, ...jaws, ...preps };
    model.multiBite = setMultibiteMetrix(metadata);
    return model;
  } catch (err) {
    return Promise.reject(err);
  }
};

const createGeometryFromCTMStream = (ctmStream) => {
  return new Promise((resolve, reject) => {
    if (ctmStream instanceof CTM.File) {
      return ctmLoader.createModel(ctmStream, (geometry) => {
        if (!isEmptyGeometry(geometry)) {
          resolve(geometry);
        }
      });
    } else if ('geometry' in ctmStream) {
      resolve(ctmStream);
    }
    resolve(null);
  });
};

const setMultibiteMetrix = (metaData) => {
  const { multi_bite_trasformation } = metaData;
  if (!multi_bite_trasformation) {
    return { ...EXTRACTED_MODEL.multiBite };
  }
  const transformationArry = JSON.parse(metaData.multi_bite_trasformation.value);
  const isAvailable = true;
  const transformationMatrix = new Matrix4().fromArray(transformationArry);
  const transformationInverseMatrix = new Matrix4().copy(transformationMatrix).invert();
  return {
    isAvailable,
    transformationMatrix,
    transformationInverseMatrix,
  };
};

const getGeometryFromZippedModel = async (zippedModel, ctmUrl) => {
  try {
    const ctmStream = await extractFile(zippedModel, ctmUrl, 'uint8array');
    const ctmFile = new CTM.File(new CTM.Stream(ctmStream));
    const geometry = await createGeometryFromCTMStream(ctmFile);
    logger.timeEnd(`extracting ${ctmUrl} type ${'uint8array'}`);
    return geometry;
  } catch (err) {
    return Promise.reject(err);
  }
};

const getJaws = async (metadataObject, zippedModel) => {
  const { jaws } = metadataObject;
  const obj = {};
  if (!jaws) return obj;

  for (let jawName in jaws) {
    const { url } = jaws[jawName];
    obj[jawName] = await getGeometryFromZippedModel(zippedModel, url);
  }
  return obj;
};

const isEmptyGeometry = (geometry) => {
  if (geometry === undefined) return true;

  geometry.computeBoundingBox();
  const { boundingBox } = geometry;
  if (!boundingBox) return true;

  const zeroVector = new Vector3();
  return boundingBox.min.equals(zeroVector) && boundingBox.max.equals(zeroVector);
};

export const getModelTextures = async (zippedModel, metaDataObj) => {
  const { jaws, preps } = metaDataObj;

  const jawsTexturePaths = keys(jaws).map((jaw) => `jaws.${jaw}.texture`);
  const prepsTexturePaths = keys(preps).map((prep) => `preps.${prep}.texture`);
  const adjacentTexturePaths = keys(preps)
    .filter((prep) => metaDataObj.preps[prep].adjacent)
    .map((prep) => `preps.${prep}.adjacent.texture`);
  const coverTexturePaths = keys(preps)
    .filter((prep) => metaDataObj.preps[prep].cover)
    .map((prep) => `preps.${prep}.cover.texture`);

  const texturePaths = [...jawsTexturePaths, ...prepsTexturePaths, ...adjacentTexturePaths, ...coverTexturePaths];

  const textures = [];

  for (let i = 0; i < texturePaths.length; i++) {
    const texturePath = texturePaths[i];
    const textureImageUrl = get(metaDataObj, texturePath);

    if (!textureImageUrl) {
      continue;
    }

    try {
      const imageType = textureImageUrl.split('.')[1];
      const base64Texture = await extractFile(zippedModel, textureImageUrl, 'base64');
      const imageDataURL = `data:image/${imageType};base64,${base64Texture}`;

      // Safari on Mac/iPad crashes with textures above 2048 pixels.
      const appleSafariOptions = { maxTextureWidth: 2048, maxTextureHeight: 2048 };
      const browserName = utils.getBrowser().name;
      const isSafari = browserName === 'Safari';

      const mipmapOptions = { ...(isSafari && appleSafariOptions) };

      const texture = await createMipmapTexture(imageDataURL, mipmapOptions);
      const modelName = texturePath
        .split('.')
        .slice(1, -1)
        .join('_');
      texture.name = `${modelName}_texture_mapping`;
      logger.timeEnd(`extracting ${textureImageUrl} type ${'base64'}`);

      textures.push(texture);
    } catch (error) {
      logger
        .info(error)
        .to(['host'])
        .data({ module: 'itr-fetcher.service.logic', material: 'getModelTextures' })
        .end();
      continue;
    }
  }

  return textures;
};

const isItrExists = async (orderId) => {
  const res = await request({
    selector: apiMapKeys.IsItrFileExists,
    queryParams: {
      orderId,
    },
  });
  return res.json();
};

export const waitForItrToBeCreated = async (environment, requestParams) => {
  const { selector, queryParams } = requestParams || {};
  return environment === Environments.EUP ? eupPolling({ queryParams }) : scannerPolling({ selector, queryParams });
};

export const waitForNiriToBeCreated = async (requestParams, progressCB) => {
  return scannerPolling({ requestParams, progressCB, modelType: 'niri' });
};

export const eupPolling = ({ queryParams }) => {
  let attempts = 0;
  const { orderId } = queryParams;
  const interval = 5000;
  const maxAttempts = 12;

  const executeItrCreationPoll = async (resolve, reject) => {
    const isItrCreated = await isItrExists(orderId);
    attempts++;

    const logMessage = `Checking for Itr file existence on EUP env, attempt number #${attempts} after ${(attempts *
      interval) /
      1000} seconds`;
    logToTimber({
      timberData: {
        action: 'downloading itr',
        module: 'itr-fetcher',
        type: 'object',
        actor: 'System',
        value: logMessage,
      },
    });

    if (isItrCreated) {
      return resolve(isItrCreated);
    } else if (attempts === maxAttempts) {
      const error = new Error('Exceeded max attempts to fetch itr file');
      error.name = 'max_attempts_exceeded';
      return reject(error);
    } else {
      setTimeout(executeItrCreationPoll, interval, resolve, reject);
    }
  };

  return new Promise(executeItrCreationPoll);
};

export const scannerPolling = ({ requestParams, progressCB }) => {
  let attempts = 0;
  const modelCreationAwait = 30000;
  const interval = 5000;
  const maxAttempts = 12;

  const executeItrCreationPoll = async (resolve, reject) => {
    const response = await downloadFile({ ...requestParams, progressCB: progressCB || (() => ({})) });
    attempts++;

    const logMessage = `Checking for itr file existence on Scanner env, attempt number #${attempts} after ${(attempts *
      interval) /
      1000} seconds`;
    logToTimber({
      timberData: {
        action: `downloading itr`,
        module: 'itr-fetcher',
        type: 'object',
        actor: 'System',
        value: logMessage,
      },
    });

    if (response && response.status && response.status === 200) {
      return resolve(response);
    } else if (attempts === maxAttempts) {
      const error = new Error(`Exceeded max attempts to fetch itr file`);
      error.name = 'max_attempts_exceeded';
      return reject(error);
    } else {
      setTimeout(executeItrCreationPoll, attempts === 1 ? modelCreationAwait : interval, resolve, reject);
    }
  };

  return new Promise(executeItrCreationPoll);
};
