import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { utils, logToTimber } from '@web-3d-tool/shared-logic';
import styles from './CompareContainer.module.css';
import CloseIcon from './assets/Close.svg';
import LoadingProgress from '../LoadingProgress/LoadingProgress';

const Card = ({
  sdk,
  order,
  selectedOrderId,
  handleOnSelectOrder,
  currentScanRef,
  strings,
  setComparedOrdersDates,
  pollRequestResponseError,
}) => {
  const { orderId, id, scanDate, IsCurrentOrder } = order;
  const { formattedDate, formattedTime } = utils.formatDateAndTime(scanDate);
  const { pluginsMessages } = strings;

  if (IsCurrentOrder) {
    setComparedOrdersDates && setComparedOrdersDates('current', scanDate);
  }

  return (
    <div
      className={`${styles.card} ${selectedOrderId === orderId && styles.active_scan} ${
        IsCurrentOrder ? styles.current_scan : ''
      } ${!orderId && styles.loading_scan}`}
      onClick={() => handleOnSelectOrder(orderId || id, sdk)}
      ref={IsCurrentOrder ? currentScanRef : null}
    >
      <div>
        {selectedOrderId === orderId && <p className={styles.compare_with}>{strings?.timelapse.compare_with}</p>}
        {IsCurrentOrder && <p>{strings?.timelapse.timelapse_current?.toUpperCase()}</p>}
        {!orderId && pollRequestResponseError && <div>{pluginsMessages.not_enabled}</div>}
        <p className={styles.date}>{formattedDate}</p>
      </div>
      <div className={styles.loadingConatiner}>
        {!orderId && !pollRequestResponseError && (
          <LoadingProgress
            width={15}
            height={15}
            classNameContainerProp={styles.spinnerLoadingAwaitContainer}
            classNameSpinnerProp={styles.classNameSpinnerProp}
          />
        )}
        <label className={styles.time}>{formattedTime}</label>
      </div>
    </div>
  );
};

const CompareContainer = ({
  sdk,
  pluginsMessages,
  className: classNameProp,
  ordersMetaData,
  selectedOrderId,
  handleOnSelectOrder,
  handleOnClickCompare,
  handleOnClickClose,
  strings,
  isModelCompare,
  sidebarWidth = 380,
  setComparedOrdersDates,
  pollRequestResponseError,
}) => {
  const className = classNames(styles.container, classNameProp);
  const scrollRef = useRef(null);
  const currentScanRef = useRef(null);
  const isDataAvaliable = !ordersMetaData || ordersMetaData.length === 0;

  useEffect(() => {
    scrollRef.current.addEventListener('scroll', () => {
      logToTimber({
        timberData: {
          eventName: `Scrolling the timelapse scans container`,
          action: 'scroll',
          module: 'UI-actions',
          type: 'div',
        },
      });
    });
  }, []);

  return (
    <div className={`${className}`} onClick={(e) => e.stopPropagation()} id="timelapse-container">
      {isDataAvaliable && <LoadingProgress classNameContainerProp={styles.spinnerContainer}></LoadingProgress>}
      <div className={styles.title} style={{ width: `${sidebarWidth}px` }}>
        <div />
        <div className={styles.title_string}>
          {isModelCompare ? strings?.pluginsNames.model_compare : strings?.pluginsNames.timelapse}
        </div>
        <div className={styles.close_icon_container}>
          <img onClick={() => handleOnClickClose()} className={styles.close_icon} src={CloseIcon} alt="Close" />
        </div>
      </div>
      <div className={styles.compare_bar}>
        <p className={classNames(styles.compare_bar_text, isDataAvaliable ? styles.hideLoadingElements : '')}>
          {strings?.timelapse.timelapse_choose_record
            .split(' ')
            .slice(0, 3)
            .join(' ')}
        </p>
        <p className={classNames(styles.compare_bar_text, isDataAvaliable ? styles.hideLoadingElements : '')}>
          {strings?.timelapse.timelapse_choose_record
            .split(' ')
            .slice(3)
            .join(' ')}
        </p>
      </div>
      <div className={styles.container_buttons}>
        <div className={`${styles.scan_list}`} ref={scrollRef}>
          {[...ordersMetaData].reverse().map((order) => (
            <Card
              sdk={sdk}
              pluginsMessages={pluginsMessages}
              key={order.orderId}
              order={order}
              selectedOrderId={selectedOrderId}
              handleOnSelectOrder={handleOnSelectOrder}
              currentScanRef={currentScanRef}
              strings={strings}
              setComparedOrdersDates={setComparedOrdersDates}
              pollRequestResponseError={pollRequestResponseError}
            />
          ))}
        </div>
      </div>
      <button
        disabled={selectedOrderId ? false : true}
        className={classNames(
          styles.compare,
          selectedOrderId ? styles.comapare_active : '',
          isDataAvaliable ? styles.hideLoadingElements : ''
        )}
        onClick={handleOnClickCompare}
      >
        {strings?.timelapse.compare}
      </button>
    </div>
  );
};

CompareContainer.propTypes = {
  /**
   * list of orders displaying in the dropdown
   */
  ordersMetaData: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Order id
       */
      orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /**
       * Scan date
       */
      scanDate: PropTypes.string.isRequired,
      /**
       * Is Current Order
       */
      IsCurrentOrder: PropTypes.bool.isRequired,
    })
  ),
  /**
   * Selected order id that will be marked
   */
  selectedOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Callback to change selected order
   */
  handleOnSelectOrder: PropTypes.func,
  /**
   * Callback to open timelapse with selected order
   */
  handleOnClickApply: PropTypes.func,
};

CompareContainer.defaultProps = {
  ordersMetaData: [],
  selectedOrderId: '',
};

export default CompareContainer;
