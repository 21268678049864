/*
    MultiTouchControlsOrthographic : Touch+Mouse controls for ThreeJS OrthographicCamera

    Usage instructions:

      1. Instantiate MultiTouchControlsOrthographic with a given camera and canvas.

        let controls = new MultiTouchControlsOrthographic(camera, canvas, options?);
        controls.setCenterOfOrbit(centerOfOrbitInWorldCoors);

      2. Subscribe to 'start' and 'change' events to invalidate the display, to trigger a render:

        controls.addEventListener('start', invalidate);
        controls.addEventListener('change', invalidate);
      
      3. Call update() method upon render

        onRender() {
            controls.update();
        }

    Internals:
    
      Coordinate systems & conventions:
      
        1. Screen coordinates 
            - (x,y) given in pixels. 
            - All touch & mouse events are given in Screen coordiantes.
            - Represented as an object {x, y}
            - The {left, right, top, bottom} are also in Screen coordiantes.

        2. World coordinates 
            - 3D points in the same coordinate system as the model being rendered.
            - Represented as Three.Vector3

      Generator/Applier pattern:
        
        Each manipulation internally instantiates a pair of classes: GENERATOR and APPLIER.
        The GENERATOR is responsible for generating TRANSFORMS in response to touch/mouse events.
        The APPLIER is responsible for applying the most recent TRANSFORM to the camera, upon rendering.

    TODO: Generalize this to PerspectiveCamera (should be quite easy).

*/

import { EventDispatcher } from 'three';

import { PZRGenerator, PZRApplier, PanGenerator } from './pzr';
import { PointerZoomApplier, PointerZoomGenerator, WheelZoomApplier, WheelZoomGenerator } from './zoom';
import { OrbitGenerator, OrbitApplier } from './orbit';
import { TapEvent } from './tap-event';
import TouchPointerAdapter from './touch-pointer-adapter';

const touchAdapter = new TouchPointerAdapter();

const changeEvent = { type: 'change' };
const startEvent = { type: 'start' };
const endEvent = { type: 'end' };

const defaultOptions = {
  // TODO: See if those numbers are the same as in WPF?
  orbitSpeed: 0.004,
  pointerZoomSpeed: 0.003,
  wheelZoomSpeed: 0.0003,
  zoomMax: 100,
  zoomMin: 0.5
};

function _xy(mouseOrTouchEvent) {
  const { pageX: x, pageY: y } = mouseOrTouchEvent;
  return { x, y };
}

class MultiTouchControlsOrthographic extends EventDispatcher {
  constructor(camera, canvas, options = {}) {
    super();

    this.camera = camera;
    this.canvas = canvas;
    this.options = { ...defaultOptions, ...options };
    this._tapEvent = new TapEvent(canvas);
    this.isEventListenersActive = false;

    this.centerOfOrbit = { x: 0, y: 0, z: 0 };
    this.selectiveEventsCancellation = false;

    this._updateScreenSize();
  }

  init() {
    this._addEventListeners();
    this._tapEvent.init();
    this.isEventListenersActive = true;
  }

  dispose() {
    this._removeEventListeners();
    this._tapEvent.dispose();
    this.isEventListenersActive = false;
  }

  // selective cancellation of some events like onWheel because we don't need it in static mode
  selectiveCancellation(isCancellation) {
    this.selectiveEventsCancellation = isCancellation;
  }

  setCenterOfOrbit(centerOfOrbit) {
    this.centerOfOrbit = centerOfOrbit;
  }

  handleResize() {
    this._updateScreenSize();
  }

  updateCamera() {
    if (this.activeManipulation && this.activeManipulation.transform) {
      this.activeManipulation.applier.updateCamera(this.activeManipulation.transform);
      this.activeManipulation.transform = undefined;
    }
  }

  // -------------- Event handlers ---------------

  _wheel = event => {
    if (this.selectiveEventsCancellation) return;

    if (this.activeManipulation && (this.activeManipulation.isPanning || this.activeManipulation.isRotating)) {
      return;
    }

    this._applyZoomManipulation(event);
    this.dispatchEvent(changeEvent);
    this.dispatchEvent(endEvent);
  };

  _pointerdown = event => {
    event.stopPropagation();

    touchAdapter.pointerdown(event);
    this.canvas.setPointerCapture(event.pointerId);

    this.activeManipulation = undefined;

    switch (touchAdapter.touches.length) {
      case 1:
        if (event.button === 0) {
          const isMouse = event.pointerType === 'mouse';
          this._startOrbitManipulation(_xy(touchAdapter.touches[0]), isMouse);
          this.dispatchEvent(startEvent);
        } else if (event.button === 1) {
          this._startZoomManipulation(_xy(touchAdapter.touches[0]));
          this.dispatchEvent(startEvent);
        } else if (event.button === 2 && !this.selectiveEventsCancellation) {
          this._startPanManipulation(_xy(touchAdapter.touches[0]));
          this.dispatchEvent(startEvent);
        }

        break;
      case 2:
        if (this.selectiveEventsCancellation) return;
        this._startPZRManipulation(_xy(touchAdapter.touches[0]), _xy(touchAdapter.touches[1]));
        this.dispatchEvent(startEvent);
        break;
      default:
      // no default
    }
  };

  _pointermove = event => {
    if (event.buttons === 0 || !this.activeManipulation) {
      touchAdapter.touches.length = 0;

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    touchAdapter.pointermove(event);

    this.activeManipulation.mouseClientPoint = this.activeManipulation.mouseClientPoint || {};
    this.activeManipulation.mouseClientX = touchAdapter.touches[0].pageX;
    this.activeManipulation.mouseClientY = touchAdapter.touches[0].pageY;

    switch (touchAdapter.touches.length) {
      case 1:
        this.activeManipulation.transform = this.activeManipulation.generator.update(_xy(touchAdapter.touches[0]));
        this.dispatchEvent(changeEvent);
        break;
      case 2:
        this.activeManipulation.transform = this.activeManipulation.generator.update(
          _xy(touchAdapter.touches[0]),
          _xy(touchAdapter.touches[1])
        );
        this.dispatchEvent(changeEvent);
        break;
      default:
        this.activeManipulation = undefined;
        this.dispatchEvent(endEvent);
    }
  };

  _pointerup = event => {
    event.preventDefault();
    event.stopPropagation();

    touchAdapter.pointerup(event);
    this.canvas.releasePointerCapture(event.pointerId);

    this.activeManipulation = undefined;
    this.dispatchEvent(endEvent);
  };

  _contextmenu = event => {
    event.preventDefault();
  };

  // -------------- Private methods ---------------

  _startPZRManipulation(touch0, touch1) {
    const {
      camera,
      options: { zoomMin, zoomMax }
    } = this;

    this.activeManipulation = {
      generator: new PZRGenerator(camera, touch0, touch1, zoomMin, zoomMax, true),
      applier: new PZRApplier(this.camera, this.screen),
      transform: undefined,
      isZooming: true,
      isPanning: true
    };
  }

  _applyZoomManipulation(event) {
    if (!this.activeManipulation || !this.activeManipulation.isZoomManipulation) {
      const {
        camera,
        options: { zoomMin, zoomMax, wheelZoomSpeed }
      } = this;

      const generator = new WheelZoomGenerator(camera, zoomMin, zoomMax, wheelZoomSpeed);

      this.activeManipulation = {
        generator,
        applier: new WheelZoomApplier(generator),
        transform: undefined,
        isZooming: true
      };
    }
    this.activeManipulation.transform = this.activeManipulation.generator.update({ wheelDelta: event.deltaY });
  }

  _addEventListeners() {
    this.eventListeners = {
      pointerdown: this._pointerdown,
      pointerup: this._pointerup,
      pointermove: this._pointermove,
      wheel: this._wheel,
      contextmenu: this._contextmenu
    };

    for (let eventType in this.eventListeners) {
      this.canvas.addEventListener(eventType, this.eventListeners[eventType], false);
    }

    this._tapEvent.addEventListener('tap2Fingers', () => {
      this.activeManipulation = {
        isPanning: false,
        isZooming: false,
        isTwoFingersTap: true
      };
      this.handleTap2Fingers(this);
      this.activeManipulation = undefined;
    });

    this._tapEvent.addEventListener('doubletap2Fingers', () => {
      this.activeManipulation = {
        isPanning: false,
        isZooming: false,
        isTwoFingersDoubleTap: true
      };
      this.handleDoubletap2Fingers(this);
      this.activeManipulation = undefined;
    });
  }

  _removeEventListeners() {
    for (let eventType in this.eventListeners) {
      this.canvas.removeEventListener(eventType, this.eventListeners[eventType], false);
    }
  }

  _updateScreenSize() {
    const box = this.canvas.getBoundingClientRect();
    const d = this.canvas.ownerDocument.documentElement;

    this.screen = {
      left: box.left + window.pageXOffset - d.clientLeft,
      top: box.top + window.pageYOffset - d.clientTop,
      width: box.width,
      height: box.height
    };
  }

  _startOrbitManipulation(pointOnScreen) {
    this.activeManipulation = {
      generator: new OrbitGenerator(this.options.orbitSpeed, pointOnScreen),
      applier: new OrbitApplier(this.camera, this.screen, this.centerOfOrbit),
      transform: undefined,
      isPanning: false,
      isRotating: true
    };
  }

  _startZoomManipulation(pointOnScreen) {
    const {
      camera,
      options: { zoomMin, zoomMax, pointerZoomSpeed }
    } = this;

    this.activeManipulation = {
      generator: new PointerZoomGenerator(pointOnScreen, camera, zoomMin, zoomMax, pointerZoomSpeed),
      applier: new PointerZoomApplier(camera),
      transform: undefined,
      isPanning: false,
      isZooming: true
    };
  }

  _startPanManipulation(pointOnScreen) {
    this.activeManipulation = {
      generator: new PanGenerator(pointOnScreen),
      applier: new PZRApplier(this.camera, this.screen),
      transform: undefined,
      isPanning: true
    };
  }
}

export { MultiTouchControlsOrthographic };
