import { get, set } from 'lodash';

window.appCache = {};

const cacheManager = {
  get: (key) => {
    return get(window.appCache, key);
  },

  set: (key, value) => {
    set(window.appCache, key, value);
  },
};

const cacheKeys = {
  MATERIALS: 'materials',
  COMPARE_MATERIALS: 'compare_materials',
  MODEL: 'model',
  VISIBILITY_OBJECT: 'visibilityObject',
  COMPARE_VISIBILITY_OBJECT: 'compareVisibilityObject',
  NIRI_DATA: 'niriData',
  PRESET: 'preset',
  THREE_OBJECTS: 'threeObjects',
  CONFIGURATION: 'configuration',
  DATA_JSON: 'data_json',
  IMAGES_META_DATA: 'images_meta_data',
  ZIPPED_DATA: 'zipped_data',
  JAW_STATE: 'jaw_state',
  FALLBACK_SHADER_ERROR: 'fallback_shader_error',
  SPLITTED_VIEW_WITH_SIDE_PLUGIN_ACTIVE: 'SPLITTED_VIEW_WITH_SIDE_PLUGIN_ACTIVE',
  SPLITTED_REVIEW_TOOL_ACTIVE_CANVAS_ID: 'SPLITTED_REVIEW_TOOL_ACTIVE_CANVAS_ID',
  IMAGE_FRAME_DIMENTIONS_CHANGE: 'IMAGE_FRAME_DIMENTIONS_CHANGE',
  CAMERA_MODEL_COMPARE: 'camera_model_compare',
  COMPARE_MODEL: 'compare_model',
  SELECTED_ORDER_DATE: 'SELECTED_ORDER_DATE',
  AUTH_TOKEN: 'AUTH_TOKEN',
  APP_SETTINGS: 'APP_SETTINGS',
  SCENES_DIMENSIONS: 'SCENES_DIMENSIONS',
  PLUGINS_INITIAL_STATE: 'PLUGINS_INITIAL_STATE',
  CANDIDATE_GRID: 'CANDIDATE_GRID',
};

export { cacheManager, cacheKeys };
