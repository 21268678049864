import { Vector4, Vector3, ShaderMaterial, DoubleSide } from 'three';
import { extend } from 'react-three-fiber';
import logger from '../../logger';

export class PhongTextureMaterial extends ShaderMaterial {
  constructor(texture, jawsPluginCurrentState, args) {
    const { uniforms, vertexShader, fragmentShader } = selectShaderForPluginState(jawsPluginCurrentState);

    super({
      uniforms: { ...uniforms, map: { ...uniforms.map, value: texture } },
      vertexShader,
      fragmentShader,
      vertexColors: true,
      side: DoubleSide
    });

    logger
      .info('Lumina new shader loaded')
      .to(['host'])
      .data({ module: 'PhongTextureMaterial', material: 'ShaderMaterial' })
      .end();
  }

  get texture() {
    return this.uniforms.map.value;
  }

  set texture(v) {
    this.uniforms.map.value = v;
  }
}

const selectShaderForPluginState = jawsPluginCurrentState => {
  if (!jawsPluginCurrentState) return phongTextureShader;
  const { isPrepsExists, preps } = jawsPluginCurrentState;

  if (isPrepsExists) {
    const isPrepSelected = Object.values(preps).some(prep => prep.checked === true);
    return isPrepSelected ? phongPrepTextureShader : phongTextureShader;
  }

  return phongTextureShader;
};

const phongTextureShader = {
  uniforms: {
    light0_dir: { type: 'v3', value: new Vector3(0.0, 0.0, 1.0) },
    light0_ambient: { type: 'v4', value: new Vector4(0.906, 0.875, 0.875, 1.0) },
    light0_diffuse: { type: 'v4', value: new Vector4(0.95, 0.914, 0.914, 1.0) },
    light0_specular: { type: 'v4', value: new Vector4(1.0, 1.0, 1.0, 1.0) },
    light0_shininess: { type: 'f', value: 600.0 },
    light0_k_amb: { type: 'f', value: 1.0 },
    light0_k_dif: { type: 'f', value: 0.225 },
    light0_k_spec: { type: 'f', value: 1.0 },
    light0_k_total: { type: 'f', value: 0.765 },

    light1_dir: { type: 'v3', value: new Vector3(-0.602394163608551, 0.4003490209579468, 0.6905373930931091) },
    light1_ambient: { type: 'v4', value: new Vector4(1.0, 1.0, 1.0, 1.0) },
    light1_diffuse: { type: 'v4', value: new Vector4(1.0, 1.0, 1.0, 1.0) },
    light1_specular: { type: 'v4', value: new Vector4(1.0, 1.0, 1.0, 1.0) },
    light1_shininess: { type: 'f', value: 600.0 },
    light1_k_amb: { type: 'f', value: 0.82 },
    light1_k_dif: { type: 'f', value: 0.19 },
    light1_k_spec: { type: 'f', value: 1.0 },
    light1_k_total: { type: 'f', value: 0.4 },
    map: { type: 't', value: null }
  },
  vertexShader: /* glsl */ `
    uniform vec3 light1_dir;
    varying vec4 myPos;
    varying vec4 myColor;
    varying vec3 myNormal;
    varying vec2 myUv;
    varying vec3 light1_dir_rot;
    attribute vec2 uvTexture;

    void main(){ 
      myNormal = mat3(modelViewMatrix) * normal;
      light1_dir_rot = mat3(modelViewMatrix) * light1_dir;
      myColor = vec4(color.rgb, 1.0);
      myUv = uvTexture;
      myPos = modelViewMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * myPos;
    }
`,
  fragmentShader: /* glsl */ `
    // light #0
    uniform vec3 light0_dir;
    uniform vec4 light0_ambient;
    uniform vec4 light0_diffuse;
    uniform vec4 light0_specular;
    uniform float light0_shininess;
    uniform float light0_k_amb;
    uniform float light0_k_dif;
    uniform float light0_k_spec;
    uniform float light0_k_total;

    // light #1
    uniform vec4 light1_ambient;
    uniform vec4 light1_diffuse;
    uniform vec4 light1_specular;
    uniform float light1_shininess;
    uniform float light1_k_amb;
    uniform float light1_k_dif;
    uniform float light1_k_spec;
    uniform float light1_k_total;

    uniform sampler2D map;

    varying vec4 myPos;
    varying vec4 myColor;
    varying vec3 myNormal;
    varying vec2 myUv;
    varying vec3 light1_dir_rot;

    void main(){ 
      vec3 N = normalize(myNormal);

      vec4 a0 = light0_k_total * light0_k_amb * light0_ambient;
      vec4 a1 = light1_k_total * light1_k_amb * light1_ambient;

      float light0_dot_N = clamp(dot(light0_dir, N), 0.0, 1.0);
      float light1_dot_N = clamp(dot(light1_dir_rot, N), 0.0, 1.0);
      vec4 d0 = light0_k_total * light0_k_dif * light0_diffuse * light0_dot_N;
      vec4 d1 = light1_k_total * light1_k_dif * light1_diffuse * light1_dot_N;

      int is_Lambert0 = int(light0_dot_N > 0.0);  
      int is_Lambert1 = int(light1_dot_N > 0.0);     

      vec3 eye_vector = normalize(-1.0 * myPos.xyz);   

      vec3 reflect_vector0 = reflect(-light0_dir, N);
      vec3 reflect_vector1 = reflect(-light1_dir_rot, N);

      float specular_lighting0_factor = float(is_Lambert0) * clamp(dot(reflect_vector0, eye_vector), 0.0, 1.0);
      float specular_lighting1_factor = float(is_Lambert1) * clamp(dot(reflect_vector1, eye_vector), 0.0, 1.0);

      float specular_lighting0_intensity = pow(specular_lighting0_factor, light0_shininess);
      float specular_lighting1_intensity = pow(specular_lighting1_factor, light1_shininess);

      vec4 s0 = light0_k_total * light0_k_spec * light0_specular * specular_lighting0_intensity;
      vec4 s1 = light1_k_total * light1_k_spec * light1_specular * specular_lighting1_intensity;

      vec4 texelColor = myColor;

      #ifdef USE_MAP 
          texelColor = texture2D( map, myUv );
      #endif 

      vec4 total_color = texelColor * (a0 + a1) + texelColor * (d0 + d1) + (s0 + s1);
      gl_FragColor = vec4(total_color.rgb, 1.0);
    }
  `
};

// Should replace with new shader for the preps when it will be provided.
const phongPrepTextureShader = phongTextureShader;

extend({ PhongTextureMaterial });
