import logger from '../logger';
import validateDataFromHost from './data-validator';
import { hostEventsErrors, hostEvents } from '../event-bus';
import { utils } from '../utils';

const { isStandaloneMode, isScannerHostEnv } = utils;

let messageCallback = null;
export const postMessageManager = {
  init(callback) {
    messageCallback = callback;
    this.bindEvents();
  },
  destroy() {
    this.unbindEvents();
  },
  bindEvents() {
    window.addEventListener('message', this.handleIncomingMessage);

    logger
      .debug('listening to post message')
      .to('host')
      .data({ module: 'post-message-manager' })
      .end();
  },
  unbindEvents() {
    window.removeEventListener('message', this.handleIncomingMessage);
  },
  handleIncomingMessage: (event) => {
    const { data: message } = event;

    const { eventName, payload } = message || {};
    if (eventName && payload) {
      message.key = hostEvents.GET_SESSION_INFO;
      message.data = payload;
    }

    const isDataValid = isStandaloneMode() || validateDataFromHost(message);
    if (!isDataValid) message.data = hostEventsErrors.DATA_IS_NOT_VALID;

    messageCallback(message);
  },
  send: (message) => {
    window.parent.postMessage(message, '*');

    logger
      .debug('sending post message')
      .to('host')
      .data({ message, module: 'post-message-manager' })
      .end();
  },
};
