export const ViewerSoftwareOptions = {
  ShouldEnableEvx: 2087,
  ShouldEnableIntraoralCameraTool: 2092,
  ShouldEnableTextureMappingInViewer: 2230,
  SideBySideCompare: 2403,
  DentalXr: 2406
};

export const SoftwareOptionsForCompany = {
  InvisalignOutcomeSimulator: 2249,
  ShouldDisableTextureMappingForCompany: 2231
};
