import { toCanvas } from 'html-to-image';
export const getNodeCanvas = async (element) => {
  return toCanvas(element);
};

export const getCaptureFilePrefix = ({ orderId, time }) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = time.getDate() > 9 ? time.getDate().toString() : `0${time.getDate().toString()}`;
  const month = monthNames[time.getMonth()];
  const hour = `${time.getHours()}-${time.getMinutes()}-${time.getSeconds()}`;
  const prefix = `ID${orderId || '0000'}_${day}-${month}-${time.getFullYear()}_T_${hour}_`;
  return prefix;
};

export const createBase64ImageFromNode = async (nodeElement, fileFormat = 'image/jpeg', outputImageQuality = 1) => {
  const canvas = await toCanvas(nodeElement);
  return canvas.toDataURL(fileFormat, outputImageQuality);
};

export const createImageWithWaterMark = async (node, watermarkURL, coords = { x: 30, y: 40 }) => {
  return new Promise((resolve, reject) => {
    const waterMark = new Image();
    getNodeCanvas(node).then((canvas) => {
      waterMark.addEventListener('load', () => {
        const ctx = canvas.getContext('2d');
        ctx.drawImage(waterMark, coords.x, canvas.height - waterMark.height - coords.y);
        const base64URL = canvas.toDataURL('image/jpeg', 1.0);
        resolve(base64URL);
      });

      waterMark.addEventListener('error', () => {
        resolve(canvas.toDataURL('image/jpeg', 1.0));
      });

      waterMark.src = watermarkURL;
    });
  });
};

export const createBase64ImageFromImg = async (img) => {
  const canvas = await toCanvas(img, { canvasWidth: img.naturalWidth, canvasHeight: img.naturalHeight });
  return canvas.toDataURL('image/jpeg', 1.0);
};
