import { get } from 'lodash';
import { Vector3 } from 'three';

export const getClosestPhotoObjectRTH = ({ jawName, intersect, imagesCenter, jawsPhotosMetadata, camera }) => {
  const RATIO_PIXEL_TO_ANGLE = 600;

  if (!intersect) {
    return null;
  }

  const xCamAxis = new Vector3();
  const yCamAxis = new Vector3();
  const zCamAxis = new Vector3();

  camera.matrixWorld.extractBasis(xCamAxis, yCamAxis, zCamAxis);

  const currentActiveJaw = get(jawsPhotosMetadata, jawName);

  const point = get(intersect, 'point');
  let minDistance = Infinity;

  const selectedPhotoObject =
    currentActiveJaw &&
    currentActiveJaw.reduce((acc, photoObj) => {
      ['niri', 'color'].forEach(key => {
        const item = get(photoObj, key) || {};
        const worldToCam = item.worldToCamMatrix;
        if (!worldToCam) {
          return;
        }
        const screenPoint = point.clone();
        screenPoint.applyMatrix4(worldToCam);
        screenPoint.set(screenPoint.x / screenPoint.z, screenPoint.y / screenPoint.z, 0);
        const rotationVector = new Vector3(...item.rawImageMatrix.slice(8, 11));
        const dotProduct = rotationVector.dot(zCamAxis);

        const distanceFromImageCenter = screenPoint.distanceTo(imagesCenter);
        const distance = 1 - dotProduct + distanceFromImageCenter / RATIO_PIXEL_TO_ANGLE;
        if (distance < minDistance) {
          minDistance = distance;
          acc = photoObj;
        }
      });
      return acc;
    }, {});

  const rowImageMatrix = get(selectedPhotoObject, 'color.rawImageMatrix');
  const dot = rowImageMatrix.slice(0, 3);
  const photosRightAxis = new Vector3(...dot);
  const rotation = photosRightAxis.dot(xCamAxis) < 0 ? 180 : 0;

  selectedPhotoObject.rotation = rotation || 0;
  return selectedPhotoObject;
};
