/**
 * This code was modified by us so we will be able to load it as module and not depend on
 * window.THREE.
 *
 *  Original:
 *
 * Loader for CTM encoded models generated by OpenCTM tools:
 *  http://openctm.sourceforge.net/
 *
 * Uses js-openctm library by Juan Mellado
 *  http://code.google.com/p/js-openctm/
 *
 * @author alteredq / http://alteredqualia.com/
 */

import { LoaderUtils, Loader, BufferGeometry, BufferAttribute } from 'three';

import logger from '../../logger';
import CTM from './ctm';

const CTMLoader = function() {};

// Load multiple CTM parts defined in JSON

CTMLoader.prototype.loadParts = function(url, callback, parameters) {
  parameters = parameters || {};

  var scope = this;

  var xhr = new XMLHttpRequest();

  var basePath = parameters.basePath ? parameters.basePath : LoaderUtils.extractUrlBase(url);

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      if (xhr.status === 200 || xhr.status === 0) {
        var jsonObject = JSON.parse(xhr.responseText);

        var materials = [],
          geometries = [],
          counter = 0;

        function callbackFinal(geometry) {
          counter += 1;

          geometries.push(geometry);

          if (counter === jsonObject.offsets.length) {
            callback(geometries, materials);
          }
        }

        // init materials

        for (var i = 0; i < jsonObject.materials.length; i++) {
          materials[i] = Loader.prototype.createMaterial(jsonObject.materials[i], basePath);
        }

        // load joined CTM file

        var partUrl = basePath + jsonObject.data;
        var parametersPart = {
          useWorker: parameters.useWorker,
          worker: parameters.worker,
          offsets: jsonObject.offsets
        };
        scope.load(partUrl, callbackFinal, parametersPart);
      }
    }
  };

  xhr.open('GET', url, true);
  xhr.setRequestHeader('Content-Type', 'text/plain');
  xhr.send(null);
};

// Load CTMLoader compressed models
//	- parameters
//		- url (required)
//		- callback (required)

CTMLoader.prototype.load = function(url, callback, parameters) {
  parameters = parameters || {};

  var scope = this;

  var offsets = parameters.offsets !== undefined ? parameters.offsets : [0];

  var xhr = new XMLHttpRequest(),
    callbackProgress = null;

  var length = 0;

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      if (xhr.status === 200 || xhr.status === 0) {
        var binaryData = new Uint8Array(xhr.response);

        var s = Date.now();

        if (parameters.useWorker) {
          var worker = parameters.worker;

          worker.onmessage = function(event) {
            var files = event.data;

            for (var i = 0; i < files.length; i++) {
              var ctmFile = files[i];
              var e1 = Date.now();

              scope.createModel(ctmFile, callback);

              var e = Date.now();
              logger
                .debug(`model load time [worker]: ${e - e1}ms, total: ${e - s}`)
                .data({ module: 'ctm-loadaer' })
                .end();
            }
          };

          worker.postMessage({ data: binaryData, offsets: offsets }, [binaryData.buffer]);
        } else {
          for (var i = 0; i < offsets.length; i++) {
            var stream = new CTM.Stream(binaryData);
            stream.offset = offsets[i];

            var ctmFile = new CTM.File(stream);

            scope.createModel(ctmFile, callback);
          }
        }
      } else {
        logger
          .error(`Couldn't load [${url}] [${xhr.status}]`)
          .data({ module: 'ctm-loader' })
          .end();
      }
    } else if (xhr.readyState === 3) {
      if (callbackProgress) {
        if (length === 0) {
          length = xhr.getResponseHeader('Content-Length');
        }

        callbackProgress({ total: length, loaded: xhr.responseText.length });
      }
    } else if (xhr.readyState === 2) {
      length = xhr.getResponseHeader('Content-Length');
    }
  };

  xhr.open('GET', url, true);
  xhr.responseType = 'arraybuffer';

  xhr.send(null);
};

CTMLoader.prototype.createModel = function(file, callback) {
  class Model extends BufferGeometry {
    constructor() {
      super();

      this.materials = [];

      var indices = file.body.indices;
      var positions = file.body.vertices;
      var normals = file.body.normals;

      var uvOcc, uvTexture, colors;

      var uvMaps = file.body.uvMaps;

      if (uvMaps !== undefined && uvMaps.length > 0) {
        uvMaps.forEach(uvMap => {
          switch (uvMap.name) {
            case 'Texture mapping color':
              uvTexture = uvMap;
              break;
            case 'Diffuse color':
              uvOcc = uvMap;
              break;
            default:
              logger
                .error(`Unknown uv map was found in model`, uvMap)
                .data({ module: 'ctm-loader' })
                .end();
              break;
          }
        });
      }

      var attrMaps = file.body.attrMaps;

      if (attrMaps !== undefined && attrMaps.length > 0 && attrMaps[0].name === 'Color') {
        colors = attrMaps[0].attr;
      }

      this.setIndex(new BufferAttribute(indices, 1));
      this.setAttribute('position', new BufferAttribute(positions, 3));

      if (normals !== undefined) {
        this.setAttribute('normal', new BufferAttribute(normals, 3));
      }

      // UV mapping for jaws textures
      if (uvTexture !== undefined) {
        this.setAttribute('uvTexture', new BufferAttribute(uvTexture.uv, 2));
        this.attributes.uvTexture.name = uvTexture.name;
      }

      // UV mapping for occlusal clearance textures
      if (uvOcc !== undefined) {
        this.setAttribute('uv', new BufferAttribute(uvOcc.uv, 2));
        this.attributes.uv.name = uvOcc.name;
      }

      if (colors !== undefined) {
        this.setAttribute('color', new BufferAttribute(colors, 4));
      }
    }
  }
  Model.prototype.constructor = Model;

  var geometry = new Model();

  // compute vertex normals if not present in the CTM model
  if (geometry.attributes.normal === undefined) {
    geometry.computeVertexNormals();
  }

  callback(geometry);
};

export default CTMLoader;
