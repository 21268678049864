import { eventBus, hostEvents } from '../event-bus';
import { OutgoingMessagesKeys } from '../constants';
import { hostCommunicationManager } from '../host-communication';
import { logToTimber, logToTimberBI, biMethods } from '../timberLogger';
import { utils } from '../utils';
import interceptor from '../interceptor/interceptor';
import logger from '../logger';

const appName = 'web-3d-tool';
const { promiseResult } = utils;

const sctService = () => {
  return promiseResult(
    new Promise(async (resolve, reject) => {
      try {
        eventBus.subscribeToEvent(hostEvents.GET_SESSION_INFO, async (parentSessionInfo) => {
          typeof parentSessionInfo === 'object' &&
            localStorage.setItem('sessionInfo', JSON.stringify(parentSessionInfo));
          await interceptor({ isSCTEnabled: true });
          resolve(true);
        });
        hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.GET_SESSION_INFO, {
          appName: appName,
          eventName: OutgoingMessagesKeys.GET_SESSION_INFO,
        });
      } catch (error) {
        logger
          .error('Failed to get sessionInfo from MIDC when SCT is on')
          .to(['analytics', 'host'])
          .data({ module: 'sct-service', err: error.message })
          .end();

        logToTimber({
          timberData: {
            action: `postMessage to parent MIDC`,
            module: 'sct-service',
            type: 'object',
            actor: 'System',
            value: {
              errorMessage: error.message,
            },
          },
        });

        logToTimberBI(
          biMethods.errorReportBiLog({
            object: 'sct-service',
            code: 'Post message to parent iframe failed',
            description: error.message,
            severity: 'Fatal - failed to get sessionInfo from MIDC when SCT is on',
          })
        );

        reject(true);
      }
    })
  );
};
export default sctService;
