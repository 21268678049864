import { Vector3 } from 'three';
import { calculateIntersectPointForImageSelection, onImageSelectedUpdateRotation } from './lumina/lumina.helper';
import { selectBestMatchFrameOnLoupeDrag } from './lumina/algorithms/static-model-image-selection-rail-alg';
import { selectBestMatchFrameOnModelRotation } from './lumina/algorithms/dynamic-model-image-selection-alg';
import { cacheManager, cacheKeys } from '../cache-manager';
import { toolsEvents } from '../event-bus/supportedKeys';

const { EVENT_ORIGINS } = toolsEvents;

export const getClosestPhotoObjectLumina = ({
  jawName,
  intersect,
  jawsPhotosMetadata,
  camera,
  rayCaster,
  eventOrigin,
  meshes,
}) => {
  const imagesMetadata = cacheManager.get(cacheKeys.IMAGES_META_DATA);
  const luminaImageMetaData = imagesMetadata && imagesMetadata[jawName].images;

  if (!intersect || !luminaImageMetaData || luminaImageMetaData.length === 0) {
    return null;
  }

  const { matrixWorld, matrixWorldInverse } = camera;
  const xCamAxis = new Vector3();
  const yCamAxis = new Vector3();
  const zCamAxis = new Vector3();
  matrixWorld.extractBasis(xCamAxis, yCamAxis, zCamAxis);

  const { ray } = rayCaster;
  const { point } = intersect;
  const rayFromLoupeViewDirection = ray.direction;

  const selectedIntersectPt = calculateIntersectPointForImageSelection(eventOrigin, point, ray, meshes);
  const intersectFromModelRotation = { point: selectedIntersectPt };

  const selected_image = getSelectBestMatchFrameFunction(
    eventOrigin,
    jawName,
    rayFromLoupeViewDirection,
    intersectFromModelRotation,
    jawsPhotosMetadata,
    luminaImageMetaData,
    zCamAxis
  );

  if (selected_image && selected_image.image) {
    return onImageSelectedUpdateRotation(selected_image, matrixWorldInverse, luminaImageMetaData);
  }

  return null;
};

const getSelectBestMatchFrameFunction = (
  eventOrigin,
  jawName,
  rayFromLoupeViewDirection,
  intersectFromModelRotation,
  jawsPhotosMetadata,
  luminaImageMetaData,
  zCamAxis
) => {
  const dataJson = cacheManager.get(cacheKeys.DATA_JSON);
  const jawsObject = {
    upper_jaw: dataJson.jaws.upper_jaw,
    lower_jaw: dataJson.jaws.lower_jaw,
  };
  const image_info = jawsObject[jawName].images[0];
  const { camera_id, scan_id } = image_info.color;

  if (camera_id == null && scan_id == null) {
    return selectBestMatchFrameOnModelRotation(
      rayFromLoupeViewDirection,
      jawName,
      intersectFromModelRotation,
      jawsPhotosMetadata,
      luminaImageMetaData,
      zCamAxis
    );
  } else {
    return eventOrigin === EVENT_ORIGINS.MODEL_ROTATION
      ? selectBestMatchFrameOnModelRotation(
          rayFromLoupeViewDirection,
          jawName,
          intersectFromModelRotation,
          jawsPhotosMetadata,
          luminaImageMetaData,
          zCamAxis
        )
      : selectBestMatchFrameOnLoupeDrag(jawName, intersectFromModelRotation, jawsPhotosMetadata);
  }
};
