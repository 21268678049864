import { get } from 'lodash';
import { Vector3, Matrix4, Raycaster, MathUtils, Group } from 'three';
import { compute_tx_diff, lin_mult_forceinline } from '../utils/threeUtils';
import const_params from '../const_params';
import { cacheManager, cacheKeys } from '../../../cache-manager';
import Rails from '../classes/rails';
import { Candidate, CandidateGrid } from '../classes/candidate_grid';
import SurfaceProjectionSegmentationGrid from '../classes/surface_projection_segmentation_grid';
import { eventBus, globalEventsKeys } from '../../../event-bus';
import { get_2d_point_in_image_px } from '../lumina.helper';

const {
  rail_score_threshold,
  angle_compliance_coefficient,
  max_angle_difference_between_view_and_image,
  max_rail_candidates,
  surface_projection_segment_size,
  preferable_angle_difference_between_view_and_image,
  rail_max_return_time,
  max_position_difference_between_sector_center_and_image_projection,
  min_image_capture_height,
  max_image_capture_height,
  preferable_image_capture_height,
  preferable_position_difference_between_sector_center_and_image_projection,
  position_compliance_coefficient,
  image_capture_height_compliance_coefficient,
  minimal_distance_approximation,
  camera_count,
} = const_params;

const getMeshAndCamera = (jaw_name) => {
  const three_objects = cacheManager.get(cacheKeys.THREE_OBJECTS);
  const { scene, camera, group } = three_objects.hasOwnProperty(jaw_name)
    ? three_objects[jaw_name]
    : three_objects['lower_jaw'];
  const { children } = group || scene.children.find((obj) => obj instanceof Group) || [];
  const mesh = children && children[0];
  return { mesh, camera };
};

export const intersection_test = (lhs_img_metadata_tx, rhs_img_metadata_tx) => {
  const { trans_diff, angle_diff } = compute_tx_diff(lhs_img_metadata_tx, rhs_img_metadata_tx);
  return trans_diff < 10 && angle_diff < 10;
};

export const calculatePerspectiveCameraProjection = ({ image_metadata, mesh, camera }) => {
  const cameraPosition = new Vector3();
  const cameraDirection = new Vector3();
  camera.getWorldPosition(cameraPosition);
  camera.getWorldDirection(cameraDirection);

  // Calculate the vector from the camera to the object
  const vectorToObj = new Vector3().subVectors(image_metadata.img_cen_on_surf_pt, cameraPosition);

  const rayCaster = new Raycaster(cameraPosition, vectorToObj.normalize(), 0, Infinity);
  rayCaster.firstHitOnly = true;

  const intersects = rayCaster.intersectObjects([mesh]);

  const distanceFromIntersectToImageCenter =
    intersects && intersects.length > 0 && intersects[0].point.distanceTo(image_metadata.img_cen_on_surf_pt);

  return distanceFromIntersectToImageCenter && distanceFromIntersectToImageCenter <= minimal_distance_approximation;
};

export const railsCandidateGrids = (rails, surface_projection_segmentation_grid, images_candidate_data) => {
  const rails_candidate_grids = new Array(rails.railsSize()).fill(null).map(
    () =>
      new CandidateGrid({
        rows: surface_projection_segmentation_grid.get_rows(),
        cols: surface_projection_segmentation_grid.get_cols(),
      })
  );

  for (let rail_index = 0; rail_index < rails.railsSize(); ++rail_index) {
    const rail_candidate_grid = rails_candidate_grids[rail_index];
    const current_rail = rails.get_rail(rail_index);
    for (let image_idx of current_rail) {
      for (let image_candidate_data of images_candidate_data[image_idx]) {
        // images with angle values below max threshold should always be preferred
        const {
          position_is_preferable,
          angle_is_preferable,
          capture_height_is_preferable,
          position_compliance,
          angle_compliance,
          capture_height_compliance,
          is_visible,
          angle_is_acceptable,
        } = image_candidate_data;

        const score =
          (position_compliance_coefficient +
            angle_compliance_coefficient +
            image_capture_height_compliance_coefficient) *
            position_is_preferable *
            angle_is_preferable *
            capture_height_is_preferable +
          position_compliance_coefficient * position_compliance +
          angle_compliance_coefficient * angle_compliance +
          image_capture_height_compliance_coefficient * capture_height_compliance;

        if (is_visible && angle_is_acceptable)
          rail_candidate_grid.set_cell_candidate({
            row: image_candidate_data.position.row,
            col: image_candidate_data.position.col,
            candidate: new Candidate(image_idx, score),
            enable_overwriting: true,
          });
      }
    }
  }

  return rails_candidate_grids;
};

export const imagesCandidateDataCalc = (
  surface_projection_segmentation_grid,
  images,
  camera_to_merge_tx,
  camera,
  mesh
) => {
  // image score-related data precalculation
  const images_candidate_data = [];
  const max_angle_difference_between_view_and_image_rad = MathUtils.degToRad(
    max_angle_difference_between_view_and_image
  );
  const preferable_angle_difference_between_view_and_image_rad = MathUtils.degToRad(
    preferable_angle_difference_between_view_and_image
  );

  for (let image_index = 0; image_index < images.length; ++image_index) {
    const image_metadata = images[image_index];
    const image_candidate_data = [];

    const { was_cam_projected, dist_from_cam_to_surf, img_cen_on_surf_pt } = image_metadata;

    if (
      was_cam_projected &&
      (dist_from_cam_to_surf >= min_image_capture_height && dist_from_cam_to_surf <= max_image_capture_height)
    ) {
      const angle_difference = image_metadata.camera_dir.angleTo(
        lin_mult_forceinline(new Vector3(0, 0, -1), camera_to_merge_tx)
      );

      const angle_is_acceptable = angle_difference <= max_angle_difference_between_view_and_image_rad;
      const angle_is_preferable = angle_difference <= preferable_angle_difference_between_view_and_image_rad;
      const angle_compliance = angle_is_preferable
        ? 1 - angle_difference / preferable_angle_difference_between_view_and_image_rad
        : 0;
      const capture_height_is_preferable = dist_from_cam_to_surf >= preferable_image_capture_height;
      const capture_height_compliance = capture_height_is_preferable ? 1 : 0;

      const is_visible = calculatePerspectiveCameraProjection({
        image_metadata,
        camera,
        mesh,
      });
      image_metadata.is_visible = is_visible;

      const segment_prox_data = surface_projection_segmentation_grid.get_positions_of_segments_from_point_neighborhood(
        img_cen_on_surf_pt,
        max_position_difference_between_sector_center_and_image_projection
      );

      for (let i = 0; i < segment_prox_data.length; i++) {
        const position_is_preferable =
          segment_prox_data[i].distance <= preferable_position_difference_between_sector_center_and_image_projection;
        const position_compliance = position_is_preferable
          ? 1 -
            segment_prox_data[i].distance / preferable_position_difference_between_sector_center_and_image_projection
          : 0;
        image_candidate_data.push({
          position: segment_prox_data[i].position,
          position_is_preferable: position_is_preferable,
          position_compliance: position_compliance,
          angle_is_acceptable: angle_is_acceptable,
          angle_is_preferable: angle_is_preferable,
          angle_compliance: angle_compliance,
          capture_height_is_preferable: capture_height_is_preferable,
          capture_height_compliance: capture_height_compliance,
          is_visible: is_visible,
        });
      }
    }
    images_candidate_data.push(image_candidate_data);
  }

  return images_candidate_data;
};

export const initialize_rails = (images_meta_data_array) => {
  const num_of_frames = images_meta_data_array.length;
  const rails = new Rails();
  let first = true;

  for (let n = 0; n < camera_count; ++n) {
    let current_rail_idx = rails.create_rail();

    for (let img_idx = 0; img_idx < num_of_frames; ++img_idx) {
      if (img_idx === 0 && first) {
        first = false;
        rails.add_image_to_rail(img_idx, current_rail_idx);
        continue;
      }

      const image_meta_data = images_meta_data_array[img_idx];
      if (!image_meta_data) continue;

      const { camera_id, timestamp } = image_meta_data;
      if (camera_id !== n) continue;

      const img_timestamp = timestamp;
      const current_rail = rails.get_rail(current_rail_idx);
      let late_return_found = false;
      let close_scans_found = false;

      for (let i = 0; i < current_rail.length; ++i) {
        const cluster_img_idx = current_rail[i];

        const cluster_img = images_meta_data_array[cluster_img_idx];
        if (!cluster_img) continue;

        const cluster_img_timestamp = cluster_img.timestamp;

        if (intersection_test(image_meta_data.cam_to_abs_tx, cluster_img.cam_to_abs_tx)) {
          close_scans_found = true;
          if (img_timestamp - cluster_img_timestamp > rail_max_return_time) {
            late_return_found = true;
          }
        }
      }

      if (close_scans_found && !late_return_found) {
        rails.add_image_to_rail(img_idx, current_rail_idx);
      } else {
        current_rail_idx = rails.create_rail();
        rails.add_image_to_rail(img_idx, current_rail_idx);
      }
    }
  }

  return rails;
};

export const refresh_grid = (jaw_name, initializedMetadataObject) => {
  const { rails, images } = initializedMetadataObject[jaw_name];
  const { mesh, camera } = getMeshAndCamera(jaw_name);
  const camera_to_merge_tx = new Matrix4().multiplyMatrices(
    new Matrix4().copy(mesh.matrixWorld).invert(),
    camera.matrixWorld
  );
  const merge_to_camera_tx = new Matrix4().copy(camera_to_merge_tx).invert();
  const surface_projection_segmentation_grid = new SurfaceProjectionSegmentationGrid(
    mesh,
    merge_to_camera_tx,
    surface_projection_segment_size
  );

  // image score-related data precalculation
  const images_candidate_data = imagesCandidateDataCalc(
    surface_projection_segmentation_grid,
    images,
    camera_to_merge_tx,
    camera,
    mesh
  );

  // rails candidate grids precalculation
  const rails_candidate_grids = railsCandidateGrids(rails, surface_projection_segmentation_grid, images_candidate_data);

  // final grid construction
  let candidate_grid = new CandidateGrid({
    rows: surface_projection_segmentation_grid.get_rows(),
    cols: surface_projection_segmentation_grid.get_cols(),
  });
  let not_added_rail_indices = Array.from(Array(rails.railsSize()).keys());
  let last_score = 0;

  for (let added_rails = 0; not_added_rail_indices.length > 0 && added_rails < max_rail_candidates; ++added_rails) {
    if (not_added_rail_indices.length > 0) {
      let best_rail_candidate_grid = null;
      let best_rail_idx = -1;
      const rails_to_remove = new Set();
      for (let rail_index of not_added_rail_indices) {
        const rail_candidate_grid = new CandidateGrid({
          grid: candidate_grid,
        });

        rail_candidate_grid.merge(rails_candidate_grids[rail_index], false);
        const current_score = rail_candidate_grid.get_score();
        if (current_score - last_score <= rail_score_threshold) {
          rails_to_remove.add(rail_index);
          continue;
        } else if (!best_rail_candidate_grid || current_score > best_rail_candidate_grid.get_score()) {
          best_rail_candidate_grid = rail_candidate_grid;
          best_rail_idx = rail_index;
        }
      }

      if (!best_rail_candidate_grid) {
        cacheManager.set(cacheKeys.CANDIDATE_GRID, { candidate_grid, surface_projection_segmentation_grid });

        eventBus.raiseEvent(globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE, {
          candidate_grid,
          surface_projection_segmentation_grid,
        });
        return candidate_grid;
      }

      candidate_grid = best_rail_candidate_grid;
      rails_to_remove.add(best_rail_idx);
      not_added_rail_indices = not_added_rail_indices.filter((iRailId) => !rails_to_remove.has(iRailId));
      last_score = candidate_grid.get_score();
    }
  }

  cacheManager.set(cacheKeys.CANDIDATE_GRID, { candidate_grid, surface_projection_segmentation_grid });

  eventBus.raiseEvent(globalEventsKeys.REFRESH_GRID_CALCULATION_COMPLETTE, {
    candidate_grid,
    surface_projection_segmentation_grid,
  });
  return candidate_grid;
};

export const selectBestMatchFrameOnLoupeDrag = (jawName, intersect, jawsPhotosMetadata) => {
  const currentActiveJaw = get(jawsPhotosMetadata, jawName);

  const { candidate_grid, surface_projection_segmentation_grid } = cacheManager.get(cacheKeys.CANDIDATE_GRID) || {};

  let selected_pt = intersect.point;

  if (intersect && candidate_grid) {
    const position = surface_projection_segmentation_grid.get_segment_position(selected_pt);
    const candidate = position && candidate_grid.get_cell_candidate(position.row, position.col);

    if (candidate) {
      const img_id = candidate.candidate_id;
      const selected_point_on_image_px = get_2d_point_in_image_px(intersect, currentActiveJaw[img_id]);

      return { img_idx: img_id, image: currentActiveJaw[img_id], p2: selected_point_on_image_px };
    }
  }
  return;
};
