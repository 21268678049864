import { logToTimberBI, biMethods } from '../index';
import { utils } from '../../utils';
import { PluginState } from '../../plugins/pluginState';
export default {
  app: {},
  plugins: {
    jaws_navigation: {
      jawsNavigationBITimberLog: ({ elementNamePrefix }, sdk) => {
        const eventName = `${elementNamePrefix} jaw`;

        logToTimberBI(
          biMethods.userActionBiLog({
            objectName: `${eventName} button`,
            action: 'click',
            objectType: 'button',
            locationParentObject: utils.getLocationParentObject(sdk),
            selectedValue: `${eventName} is clicked`,
            automationId: '',
          })
        );
      },
    },
    loupeDragEndEventBILog: ({ sdk, id, status, globals, toolsConstants }) => {
      if (status === PluginState.Inactive) {
        const { NiriAndIoc, NIRI, IOC } = toolsConstants;
        const niriPluginStatus = sdk.getState(NIRI.id);
        const iocPluginStatus = sdk.getState(IOC.id);

        if (id === NiriAndIoc.id) {
          globals.intersectPoint = null;
        }
        if (id === NIRI.id && iocPluginStatus === PluginState.Inactive) {
          globals.intersectPoint = null;
        }
        if (id === IOC.id && niriPluginStatus === PluginState.Inactive) {
          globals.intersectPoint = null;
        }
      }

      logToTimberBI(
        biMethods.userActionBiLog({
          objectName: '5D Review Tool Plugin',
          action: 'click',
          objectType: 'button',
          locationParentObject: sdk && utils.getLocationParentObject(sdk),
          selectedValue: 'Review tool loupe ended',
          automationId: '',
        })
      );
    },
  },
  shared_logic: {},
  shared_ui: {},
};
