import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UpperJaw from './UpperJaw';
import LowerJaw from './LowerJaw';
import LowerJawToggle from './LowerJawToggle';
import UpperJawToggle from './UpperJawToggle';
import Bite from './Bite';
import PrepToggle from './PrepToggle';
import styles from './Jaws.module.css';

const propTypes = {
  /**
   * If `true`, bite is disabled
   */
  biteDisabled: PropTypes.bool,
  /**
   * If `true`, lower jaw is disabled
   */
  lowerDisabled: PropTypes.bool,
  /**
   * If `true`, lower jaw is selected
   */
  lowerSelected: PropTypes.bool,
  /**
   * Callback, fired when a jaw element is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * If `true`, upper jaw is disabled
   */
  upperDisabled: PropTypes.bool,
  /**
   * If `true`, upper jaw is selected
   */
  upperSelected: PropTypes.bool,
  /**
   * If 'true', default lower jaw exists
   */
  lowerDefaultExists: PropTypes.bool,
  /**
   * If 'true', default upper jaw exists
   */
  upperDefaultExists: PropTypes.bool,
  /**
   * If 'true', lower pretreatment jaw exists
   */
  lowerPretreatmentExists: PropTypes.bool,
  /**
   * If 'true',  upper pretreatment jaw exists
   */
  upperPretreatmentExists: PropTypes.bool,
  /**
   * 0 jaw is not selected, 1 first jaw selected, 2 - second jaw selected, null - toggle is not enabled for current jaw
   */
  upperJawToggleSelectedOption: PropTypes.oneOf(['0', '1', '2', null]),
  /**
   * 0 jaw is not selected, 1 first jaw selected, 2 - second jaw selected, null - toggle is not enabled for current jaw
   */
  lowerJawToggleSelectedOption: PropTypes.oneOf(['0', '1', '2', null]),
  /**
   * Is MultiBite enabled
   */
  isMultiBite: PropTypes.bool,
  /**
   * Is multiBite Active or not
   */
  multiBiteActive: PropTypes.bool,
  /**
   * object containing all available preps
   */
  preps: PropTypes.object,
  /**
   * Is Jaws Navigation Visible
   */
  isVisible: PropTypes.bool,
};

const defaultProps = {
  biteDisabled: false,
  lowerDisabled: false,
  lowerSelected: false,
  upperDisabled: false,
  upperSelected: false,
  lowerDefaultExists: false,
  upperDefaultExists: false,
  lowerPretreatmentExists: false,
  upperPretreatmentExists: false,
  upperJawToggleSelectedOption: null,
  lowerJawToggleSelectedOption: null,
  isMultiBite: false,
  multiBiteActive: false,
  preps: {},
  isVisible: true,
};

const jawsVisibility = {};

const Jaws = (props) => {
  const {
    biteDisabled,
    lowerDisabled,
    lowerSelected,
    upperDisabled,
    upperSelected,
    onClick,
    upperJawToggleSelectedOption,
    lowerJawToggleSelectedOption,
    isMultiBite,
    multiBiteActive,
    className: classNameProp,
    preps,
    style,
    isVisible,
    isAnyObjectVisibleOnScreen,
    isModelCompared,
  } = props;

  jawsVisibility.isAnyObjectVisibleOnScreen = isAnyObjectVisibleOnScreen;

  const upperJawProps = {
    className: styles.upperJaw,
    disabled: upperDisabled,
    selected: upperSelected,
    onClick: () => onClick('upper', props),
  };

  const lowerJawProps = {
    className: styles.lowerJaw,
    disabled: lowerDisabled,
    selected: lowerSelected,
    onClick: () => onClick('lower', props),
  };

  const upperJawToggleProps = {
    className: styles.upperJaw,
    selectedOption: upperJawToggleSelectedOption,
    onClick: () => onClick('upper_toggle', props),
  };

  const lowerJawToggleProps = {
    className: styles.lowerJaw,
    selectedOption: lowerJawToggleSelectedOption,
    onClick: () => onClick('lower_toggle', props),
  };

  const biteProps = {
    className: styles.bite,
    disabled: biteDisabled,
    isMultiBite: isMultiBite && !isModelCompared,
    multiBiteActive,
    onClick: () => onClick('bite', props),
  };

  const className = classNames(classNameProp, styles.jaws);

  return isVisible ? (
    <div className={className} style={style}>
      {!upperJawToggleSelectedOption || isModelCompared ? (
        <UpperJaw {...upperJawProps} />
      ) : (
        <UpperJawToggle {...upperJawToggleProps} />
      )}
      <Bite {...biteProps} />
      {!lowerJawToggleSelectedOption || isModelCompared ? (
        <LowerJaw {...lowerJawProps} />
      ) : (
        <LowerJawToggle {...lowerJawToggleProps} />
      )}

      {!isModelCompared &&
        preps &&
        Object.values(preps).map((prep) => {
          const className = classNames(styles.prepToggle, styles[`ada${prep.id}`]);
          const prepProps = {
            ...prep,
            onClick: () => onClick(`ada${prep.id}`, props),
            key: prep.id,
            className,
          };

          return <PrepToggle {...prepProps} />;
        })}
    </div>
  ) : null;
};

Jaws.propTypes = propTypes;
Jaws.defaultProps = defaultProps;
Jaws.jawsVisibility = jawsVisibility;

export default Jaws;
