import logger from '../../logger';
import { getITRModelWithPolling, fetchNiriFileWithPolling } from '../../api-requests/model-fetcher/itr-fetcher.service';
import * as configuration from '../../constants/configurationValues.constants';
import { settingsManager } from '../../settings-manager';
import { Environments } from '../../constants/environment.constants';

const getNiriFilePath = () => {
  return settingsManager.getConfigValue(configuration.niri);
};

const getModelUrl = () => {
  return settingsManager.getConfigValue(configuration.model);
};

const getNiriFile = async progressCB => {
  const niriFilePath = getNiriFilePath();
  if (niriFilePath) {
    const requestParams = {
      url: niriFilePath
    };

    return await fetchNiriFileWithPolling({
      requestParams,
      environment: Environments.SCANNER,
      progressCB
    });
  } else {
    logger
      .error('No NIRI file path')
      .data({ module: 'scannerRequests' })
      .end();
  }
};

const getModel = async () => {
  const modelUrl = getModelUrl();
  const requestParams = {
    url: modelUrl
  };

  return await getITRModelWithPolling(Environments.SCANNER, requestParams);
};

const getItrModel = async () => {
  return await getModel();
};

export { getNiriFile, getItrModel, getModelUrl, getNiriFilePath };
