import { handleRequest } from './apiService-helper';
import { eventBus, globalEventsKeys } from '../event-bus';
import { OutgoingMessagesKeys } from '../constants';
import { hostCommunicationManager } from '../host-communication';
import logger from '../logger';

const serviceName = 'refresh-request-service';
const SESSION_INFO = 'sessionInfo';
const appName = 'web-3d-tool';

const refreshRequest = (() => {
  let executed = false;

  const reset = () => {
    executed = false;
  };

  const refresh = async () => {
    if (!executed) {
      executed = true;
      try {
        const sessionInfo = JSON.parse(localStorage.getItem(SESSION_INFO));
        const { refreshTokenUrl, sessionId } = sessionInfo || {};
        const { origin, pathname } = refreshTokenUrl && sessionId && new URL(refreshTokenUrl);

        const refreshResult =
          origin &&
          pathname &&
          (await handleRequest({
            url: `${origin}${pathname}?sessionId=${sessionId}&appId=${appName}`,
            module: serviceName,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Refresh: true,
            },
          }));

        const { status } = refreshResult;

        switch (true) {
          case status >= 200 && status < 300:
            const refreshTokenResult = refreshResult && (await refreshResult.json());
            const { accessToken: newAccessToken } = refreshTokenResult;

            newAccessToken &&
              localStorage.setItem(SESSION_INFO, JSON.stringify({ ...sessionInfo, accessToken: newAccessToken }));

            eventBus.raiseEvent(globalEventsKeys.REFRESH_TOKEN, { isRefreshed: !!newAccessToken });
            reset();
            break;
          case status === 401:
            hostCommunicationManager.sendMessageToHost(OutgoingMessagesKeys.TOKEN_EXPIRED, {
              appName: appName,
              eventName: OutgoingMessagesKeys.TOKEN_EXPIRED,
              payload: sessionId,
            });
            break;
          default:
            eventBus.raiseEvent(globalEventsKeys.REFRESH_TOKEN, { isRefreshed: false });
            break;
        }
      } catch (error) {
        logger
          .info('Network')
          .to(['analytics', 'host'])
          .data({ ...error, module: serviceName })
          .end();

        eventBus.raiseEvent(globalEventsKeys.REFRESH_TOKEN, { isRefreshed: false });
      }
    }
  };

  refresh.reset = reset;

  return refresh;
})();

export default refreshRequest;
