import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { utils, timberWrapperService, currentActiveDebugFunctions } from '@web-3d-tool/shared-logic';
import { debounce } from 'lodash';
import { Vector2 } from 'three';
import PinchZoomPan from './PinchZoomPan/PinchZoomPan';
import defaultStyles from './ImageFrame.module.css';
import styles360 from './ImageFrame360.module.css';

const ImageFrame = (props) => {
  const { debug_image_frame } = currentActiveDebugFunctions || {};
  const is360 = utils.getIs360HubEnabled();
  const styles = is360 ? styles360 : defaultStyles;

  const {
    width,
    height,
    src,
    brightness,
    contrast,
    rotation,
    onChange,
    left,
    top,
    scale,
    id,
    dataBiType,
    selectedPointOnImage,
    isEnlargedFrame,
  } = props;

  const isLuminaScan = utils.getIsScanOriginLumina();
  const transform = 'rotate('.concat(90, 'deg)');
  const isLuminaRotation = !!(rotation === 90 || rotation === 270);
  const runOnce = true;
  const inlineStyle = isLuminaRotation ? { width, height, transform } : { width, height };
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [imageOriginalSize, setImageOriginalSize] = useState({ width: 0, height: 0 });
  const imageRef = useRef(null);
  const imageDimensionsRef = useRef({
    scale,
    top,
    left,
  });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;
      setImageOriginalSize({ width, height });
      setLoadingComplete(true);
    };
    img.src = src;

    if (isLuminaScan && !!isEnlargedFrame) {
      let originalImage = new Image();
      originalImage.src = src;
      /*
        The original image size of the Lumina images is 960X540 so we need to preserve the aspect ratio in enlarged images
      */
      const targetWidth = 1200;
      const targetHeight = 675;

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

      const dataURL = canvas.toDataURL('image/jpeg');
      img.src = dataURL;
    }
  }, [src, runOnce, isEnlargedFrame, isLuminaScan]);

  useEffect(() => {
    const calcImageSize = (containerWidth, containerHeight, imageWidth, imageHeight) => {
      const containerRatio = containerWidth / containerHeight;
      const imageRatio = imageWidth / imageHeight;
      let imgWidth;
      let imgHeight;

      if (utils.getIsScanOriginLumina()) {
        return { width: imageWidth, height: imageHeight };
      }

      if (containerRatio === imageRatio) {
        imgWidth = containerWidth;
        imgHeight = containerHeight;
      } else if (containerRatio > imageRatio) {
        imgWidth = containerWidth;
        imgHeight = imageHeight * (containerWidth / imageWidth);
      } else {
        imgWidth = imageWidth * (containerHeight / imageHeight);
        imgHeight = containerHeight;
      }

      return { width: imgWidth, height: imgHeight };
    };

    if (loadingComplete) {
      const calculatedSize = calcImageSize(width, height, imageOriginalSize.width, imageOriginalSize.height);
      setImageSize(calculatedSize);
    }
  }, [
    width,
    height,
    src,
    loadingComplete,
    imageOriginalSize.width,
    imageOriginalSize.height,
    isLuminaRotation,
    isEnlargedFrame,
  ]);

  useEffect(() => {
    imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
  }, [brightness, contrast]);

  const getNewValuesFromEvent = (args) => {
    const { scale: newScale, top: newTop, left: newLeft } = args;
    const { scale: prevScale, top: prevTop, left: prevLeft } = imageDimensionsRef.current;
    const hasNewValuesFromEvent = !(newScale === prevScale && newTop === prevTop && newLeft === prevLeft);

    imageDimensionsRef.current = {
      scale: newScale,
      top: newTop,
      left: newLeft,
    };

    if (hasNewValuesFromEvent) {
      if (newScale !== prevScale) {
        return 'zoom';
      } else {
        return 'pan';
      }
    }
    return '';
  };

  const debouncedHandlePinchZoomPanLog = useCallback(
    debounce((args) => {
      const eventType = getNewValuesFromEvent(args);

      if (eventType) {
        timberWrapperService.timber.shared_ui.imageFrame.imageFrameTimberLog({ eventType, eventSource: dataBiType });
      }
    }, 1000),
    []
  );

  const handlePinchZoomPanChange = (args) => {
    if (onChange) {
      onChange(args);
    }
    debouncedHandlePinchZoomPanLog(args);
  };

  const getMaxMovementFromPointInImageToFrameCenter = (imageSize, selectedPointOnImage, rotation) => {
    const imageMovingRangeLeftPx = -Math.abs(imageSize.width - width);
    const imageMovingRangeTopPx = -Math.abs(imageSize.height - height);
    const frameCenterPt = new Vector2(width / 2, height / 2);

    const convertPointOnImageToFrameCoords = ({ point, frameSize, imageSize }) => {
      const x_scale = imageSize.width / frameSize.width || 1;
      const y_scale = imageSize.height / frameSize.height || 1;
      const x_pos = Math.ceil(point.x / x_scale);
      const y_pos = Math.ceil(point.y / y_scale);
      return { x_pos, y_pos };
    };

    const pointOnImageInFrameCoords = convertPointOnImageToFrameCoords({
      point: selectedPointOnImage,
      frameSize: { width, height },
      imageSize,
    });
    const delta_x = frameCenterPt.x - pointOnImageInFrameCoords.x_pos;
    const delta_y = frameCenterPt.y - pointOnImageInFrameCoords.y_pos;

    const x_adj = -Math.abs(pointOnImageInFrameCoords.x_pos - delta_x);
    const y_adj = -Math.abs(pointOnImageInFrameCoords.y_pos - delta_y);

    let y_adj_normalized = 0;
    let x_adj_normalized = 0;

    if (rotation === 180 || rotation === 270) {
      const x_adj_rotation = -Math.abs(imageMovingRangeLeftPx - x_adj);
      const y_adj_totation = -Math.abs(imageMovingRangeTopPx - y_adj);
      x_adj_normalized = x_adj_rotation < imageMovingRangeLeftPx ? imageMovingRangeLeftPx : x_adj_rotation;
      y_adj_normalized = y_adj_totation < imageMovingRangeTopPx ? imageMovingRangeTopPx : y_adj_totation;
    } else {
      x_adj_normalized = x_adj < imageMovingRangeLeftPx ? imageMovingRangeLeftPx : x_adj;
      y_adj_normalized = y_adj < imageMovingRangeTopPx ? imageMovingRangeTopPx : y_adj;
    }

    return { moveLeft: -Math.abs(x_adj_normalized), moveTop: -Math.abs(y_adj_normalized) };
  };

  const getTopLeft = (() => {
    const eventType = getNewValuesFromEvent({ top, left, scale });
    const isLuminaScanOrigin = utils.getIsScanOriginLumina();

    if (!eventType && isLuminaScanOrigin) {
      const getMaxMoveImage = getMaxMovementFromPointInImageToFrameCenter(imageSize, selectedPointOnImage, rotation);
      return { left: getMaxMoveImage.moveLeft, top: getMaxMoveImage.moveTop };
    }
    return { top, left };
  })();

  return (
    <div style={inlineStyle} className={styles.container}>
      <PinchZoomPan
        doubleTapBehavior="zoom"
        position="center"
        minScale={1}
        initialScale={1}
        maxScale={10}
        rotation={!isLuminaRotation ? rotation : rotation === 270 ? 180 : 0}
        frameWidth={width}
        frameHeight={height}
        onChange={handlePinchZoomPanChange}
        left={getTopLeft.left}
        top={getTopLeft.top}
        scale={scale}
        inversePan={isLuminaRotation}
        debugElement={
          !!debug_image_frame && {
            loupeCoords: selectedPointOnImage,
            frameCenter: { x: width / 2 + (isLuminaRotation ? 0 : 30), y: height / 2 },
          }
        }
      >
        <img
          alt=""
          ref={imageRef}
          src={src}
          width={imageSize.width}
          height={imageSize.height}
          data-test-id={id}
          id={id}
        />
      </PinchZoomPan>
    </div>
  );
};

ImageFrame.defaultProps = {
  width: 200,
  height: 200,
  src: '',
  brightness: 100,
  contrast: 100,
  rotation: 0,
};

ImageFrame.propTypes = {
  /**
   * The image width
   */
  width: PropTypes.number,
  /**
   * The image width
   */
  height: PropTypes.number,
  /**
   * Image source
   */
  src: PropTypes.string.isRequired,
  /**
   * The number of percentage of brightness apply to the image
   */
  brightness: PropTypes.number,
  /**
   * The number of percentage of contrast apply to the image
   */
  contrast: PropTypes.number,
  /**
   * The rotation angle of the image
   */
  rotation: PropTypes.number,
  /**
   * Callback, fired when pinch, zoom, pan
   */
  onChange: PropTypes.func,
  /**
   * having 360 style
   */
  is360: PropTypes.bool,
};

export default ImageFrame;
