class Rails {
  constructor(image_rails = []) {
    this.image_rails = image_rails;
  }

  create_rail() {
    this.image_rails.push([]);
    return this.image_rails.length - 1;
  }

  add_image_to_rail(img_idx, rail_idx) {
    this.image_rails[rail_idx].push(img_idx);
  }

  get_rail(rail_idx) {
    return this.image_rails[rail_idx];
  }

  get_image_rail_index(img_idx) {
    const foundRail = this.image_rails.find((rail) => rail.includes(img_idx));
    return this.image_rails.indexOf(foundRail);
  }

  railSize(rail_idx) {
    return this.get_rail(rail_idx).length;
  }

  railsSize() {
    return this.image_rails.length;
  }
}

export default Rails;
