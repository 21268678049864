import { Vector3, Vector2, Matrix4, Box3 } from 'three';
import Rect from './rect';
class SurfaceProjectionSegmentationGrid {
  constructor(mesh, merge_to_camera_tx, segment_size) {
    this.merge_to_camera_tx = new Matrix4().copy(merge_to_camera_tx);
    this.segment_size = segment_size;

    const newGeometry = mesh.geometry.clone();
    newGeometry.applyMatrix4(merge_to_camera_tx);

    const bbox = new Box3().setFromBufferAttribute(newGeometry.attributes.position);
    const min = bbox.min;
    const max = bbox.max;

    this.bbox = bbox;
    this.cols = Math.ceil((max.x - min.x) / this.segment_size);
    this.rows = Math.ceil((max.y - min.y) / this.segment_size);
    this.area = new Rect({
      x_min: min.x,
      x_max: min.x + this.cols * this.segment_size,
      y_min: min.y,
      y_max: min.y + this.rows * this.segment_size,
    });
  }

  get_projected_segment_position(i_projected_point) {
    const row = Math.floor((i_projected_point.y - this.area.y_min) / this.segment_size);
    const col = Math.floor((i_projected_point.x - this.area.x_min) / this.segment_size);
    return this.area.includesPoint(i_projected_point) ? { row, col } : null;
  }

  get_positions_of_segments_from_point_neighborhood(i_point, i_neighborhood_radius) {
    const neighbor_segments = [];
    const projected_point = this.project_point(i_point);
    const position = projected_point && this.get_projected_segment_position(projected_point);

    if (!projected_point || !position) return neighbor_segments;

    const neighborhood_segments_radius = Math.ceil(i_neighborhood_radius / this.segment_size);

    for (
      let row = Math.max(position.row - neighborhood_segments_radius, 0);
      row <= Math.min(position.row + neighborhood_segments_radius, this.rows - 1);
      ++row
    )
      for (
        let col = Math.max(position.col - neighborhood_segments_radius, 0);
        col <= Math.min(position.col + neighborhood_segments_radius, this.cols - 1);
        ++col
      ) {
        const segment_center = new Vector2(
          this.area.x_min + (col + 0.5) * this.segment_size,
          this.area.y_min + (row + 0.5) * this.segment_size
        );
        const distance = new Vector2().subVectors(segment_center, projected_point).length();
        if (distance < i_neighborhood_radius) neighbor_segments.push({ position: { row, col }, distance });
      }
    return neighbor_segments;
  }

  get_rows() {
    return this.rows;
  }

  get_cols() {
    return this.cols;
  }

  transform_to_projection_basis(i_point) {
    return new Vector3(...i_point.toArray()).applyMatrix4(this.merge_to_camera_tx);
  }

  project_point(i_point) {
    const point_in_projection_basis = this.transform_to_projection_basis(i_point);
    const projected_point = new Vector2(point_in_projection_basis.x, point_in_projection_basis.y);
    return this.area.includesPoint(projected_point) ? projected_point : null;
  }

  get_segment_position(i_projected_point) {
    const projected_point = this.project_point(i_projected_point);
    return this.get_projected_segment_position(projected_point);
  }
}

export default SurfaceProjectionSegmentationGrid;
