import { get } from 'lodash';

const cache = {};

const checkIfExistsInCache = (url) => {
  const cachedResponse = get(cache, url);

  if (cachedResponse) {
    return cachedResponse.clone();
  }
  return null;
};

const addToCache = (url, res) => {
  cache[url] = res.clone();
};

const deleteFromCache = (url) => {
  delete cache[url];
};

const getCache = () => {
  return cache;
};

export default {
  checkIfExistsInCache,
  deleteFromCache,
  addToCache,
  getCache,
};
