import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { utils } from '@web-3d-tool/shared-logic';
import { StyledPieCenter } from './styled/Center';
import { StyledPieMenu } from './styled/PieMenu';
import MenuSlice from './Slice/MenuSlice';

const CircularMenu = ({ explorers }) => {
  const calculatedValues = useMemo(() => {
    const width = utils.calcWindowWidth();
    return { radius: width * 0.205 + 'px', centerRadius: width * 0.095 + 'px', fontSize: (width * 1.3) / 100 + 'px' };
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <StyledPieMenu
        attrs={{ id: 'circular-menu' }}
        radius={calculatedValues.radius}
        centerRadius={calculatedValues.centerRadius}
        centerX="50%"
        centerY="50%"
        Center={StyledPieCenter}
      >
        {explorers.map(explorer => (
          <MenuSlice key={explorer.id} explorer={explorer} fontSize={calculatedValues.fontSize} />
        ))}
      </StyledPieMenu>
    </div>
  );
};

CircularMenu.propTypes = {
  /**
   * Array of explorers
   */
  explorers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Explorer id
       */
      id: PropTypes.string.isRequired,
      /**
       * Call back to active explorer
       */
      activateExplorer: PropTypes.func.isRequired,
      /**
       * explorer title
       */
      title: PropTypes.string.isRequired,
      /**
       * Is explorer enabled boolean
       */
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired
};

// CircularMenu.defaultProps = {
//   explorers: []
// };

export default CircularMenu;
