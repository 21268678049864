import { keys, get, isEmpty } from 'lodash';
import { Vector3, Matrix4 } from 'three';
import logger from '../logger';

export const getImagesBlobsFromZippedData = async (zippedData) => {
  const re = /(.jpg|.png|.gif|.ps|.jpeg|.webp)$/;
  const blobsPromisesArr = keys(zippedData.files)
    .filter((fileName) => re.test(fileName.toLowerCase()))
    .map((fileName) => {
      const file = zippedData.files[fileName];
      return file.async('blob').then((blob) => [fileName, URL.createObjectURL(blob)]);
    });

  try {
    const arr = await Promise.all(blobsPromisesArr);
    return arr.reduce((acc, [fileName, blob]) => {
      acc[fileName] = blob;
      return acc;
    }, {});
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getExistedScansObj = (dataJson) => {
  const jaw = !isEmpty(dataJson.jaws.upper_jaw) ? dataJson.jaws.upper_jaw : dataJson.jaws.lower_jaw;

  const hasImages = jaw.images && !!jaw.images.length;
  const isNiriMode = !!hasImages && !!jaw.images[0].niri;
  const isColorMode = !!hasImages && !!jaw.images[0].color;
  return {
    niri: isNiriMode,
    color: isColorMode,
  };
};

export const getImageCenter = (dataJson) => {
  if (dataJson.camera_to_pixel) {
    const cameraToPixelArray = JSON.parse(dataJson.camera_to_pixel);
    return new Vector3(cameraToPixelArray[2], cameraToPixelArray[5], 0);
  } else {
    return new Vector3(385, 301, 0);
  }
};

export const getScanToCamMatrix = (dataJson) => {
  const scanToCam = new Matrix4();
  const cameraToPixel = new Matrix4();
  const scanToCamTx = new Matrix4();

  const cameraToPixelArray = dataJson.camera_to_pixel
    ? JSON.parse(dataJson.camera_to_pixel)
    : [4000.216553, 0.731281, 385.873291, 0.0, 4009.794678, 301.139252];

  const scanToCamTxArray = dataJson.scan_to_cam_tx
    ? JSON.parse(dataJson.scan_to_cam_tx)
    : [
        0.067509,
        0.99764,
        -0.012496,
        0.0,
        0.997695,
        -0.067416,
        0.00769,
        0.0,
        0.00683,
        -0.012987,
        -0.999892,
        0.0,
        -11.365092,
        -7.572707,
        101.2724,
        1.0,
      ];

  cameraToPixel.set(...cameraToPixelArray.slice(0, 3), 0, ...cameraToPixelArray.slice(3, 6), 0, 0, 0, 1, 0, 0, 0, 0, 1);
  scanToCamTx.set(...scanToCamTxArray);
  scanToCamTx.transpose();

  scanToCam.multiplyMatrices(cameraToPixel, scanToCamTx);

  return scanToCam;
};

export const getWorldToCamMat = (local_to_world_tx, scanToCam) => {
  const worldToCam = new Matrix4();
  const inverseImageMat = new Matrix4();
  const imgMatrix = new Matrix4().set(...JSON.parse(local_to_world_tx));

  // get image local_to_world_tx - local to world transformation
  // each niri image and color image has this kind of matrix in its metadata
  // transpose is called in order to get the matrix in the right order (0,0,0,1) is the last row.
  inverseImageMat.copy(imgMatrix.transpose()).invert();

  // we multiply imgMatrix in order to get it in local camera coordinates
  worldToCam.multiplyMatrices(scanToCam, inverseImageMat);

  return worldToCam;
};

export const getImagesMetaData = (dataJson, jawKey, scannedTypesObj, blobsObj) => {
  const { niri: niriExists, color: colorExists } = scannedTypesObj;
  if (!niriExists && !colorExists) return null;

  const jaw = get(dataJson, `jaws.${jawKey}`);
  if (!jaw) {
    logger
      .to(['host'])
      .error(`${jawKey} - niri file is missing or corrupted`)
      .data({ module: 'niri-manager' })
      .end();

    return null;
  }

  const scanToCamMatrix = getScanToCamMatrix(dataJson);
  return jaw.images.reduce((acc, image) => {
    const color = colorExists && {
      url: image.color.url,
      rawImageMatrix: JSON.parse(image.color.local_to_world_tx),
      worldToCamMatrix: getWorldToCamMat(image.color.local_to_world_tx, scanToCamMatrix),
      blob: get(blobsObj, image.color.url),
      timestamp: image.color.timestamp,
    };

    const niri = niriExists && {
      url: image.niri.url,
      rawImageMatrix: JSON.parse(image.niri.local_to_world_tx),
      worldToCamMatrix: getWorldToCamMat(image.niri.local_to_world_tx, scanToCamMatrix),
      blob: get(blobsObj, image.niri.url),
    };

    const imageMetaItem = niriExists ? { niri, color } : { color };

    acc.push(imageMetaItem);
    return acc;
  }, []);
};

export const getPanoramaData = (dataJson, panoramaKey, blobsObj) => {
  const panorama = get(dataJson, panoramaKey);
  if (!panorama) return null;

  Object.keys(panorama).forEach((jawKey) => {
    const url = `${panoramaKey}/${jawKey}.png`;
    const blob = get(blobsObj, url);

    if (!blob) {
      logger
        .to(['host'])
        .error(`${jawKey} - niri file is missing or corrupted`)
        .data({ module: 'niri-manager' })
        .end();
    }

    panorama[jawKey].blob = blob;
  });

  return panorama;
};

/**
 * 1D to 2D array according to set matrix size parameter
 * @param {*} arr single dimensional array of size matrixSize * matrixSize
 * @param {*} matrixSize the matrix's size
 */
export const singleDimentionalArrayToMatrix = (arr, transpose = false, matrixSize = 4) => {
  let array = [...arr];
  if (!array || !array.length || array.length < matrixSize * matrixSize) {
    return undefined;
  }
  const matrix = [];

  for (let i = 0; i < matrixSize; i++) {
    const slice = array.slice(0, matrixSize);
    array = array.slice(matrixSize);
    matrix.push([...slice]);
  }

  const matrix4 = new Matrix4();
  matrix4.set(...matrix[0], ...matrix[1], ...matrix[2], ...matrix[3]);
  return transpose ? matrix4.transpose() : matrix4;
};
