const Validator = require('jsonschema').Validator;
const { hostEvents } = require('../event-bus');

const validator = new Validator();

const timelapseDataScheme = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      OrderId: { type: 'number' },
      ScanDate: { type: 'string' },
      IsCurrentOrder: { type: 'boolean' },
    },
    required: ['OrderId', 'ScanDate', 'IsCurrentOrder'],
  },
};

const IOSimDataScheme = {
  type: 'object',
  properties: {
    simulationStatus: { oneOf: [{ type: 'number' }, { type: 'string' }] },
    startSimulationUTCTime: { type: 'string' },
    expectedDurationInSeconds: { oneOf: [{ type: 'number' }, { type: 'string' }] },
  },
  required: ['simulationStatus', 'startSimulationUTCTime'],
};

const sessionInfoScheme = {
  type: 'object',
  properties: {
    accessToken: { type: 'string' },
    sessionId: { type: 'string' },
    refreshTokenUrl: { type: 'string' },
  },
  required: ['accessToken', 'sessionId', 'refreshTokenUrl'],
};

export default function validateDataFromHost({ key, data }) {
  const schemas = {
    [hostEvents.TIMELAPSE_DATA]: timelapseDataScheme,
    [hostEvents.TAKE_SIMULATION_PROGRESS_STATUS]: IOSimDataScheme,
    [hostEvents.GET_SESSION_INFO]: sessionInfoScheme,
  };

  return schemas.hasOwnProperty(key) ? validator.validate(data, schemas[key]).valid : true;
}
