import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SpinnerProgress.module.css';

const SpinnerProgress = props => {
  const styleValues = useMemo(() => {
    const { width, height, classNameContainerProp, classNameSpinnerProp, spinnerVarProps, ...other } = props;
    return { width, height, classNameContainerProp, classNameSpinnerProp, spinnerVarProps, ...other };
  }, [props]);

  const classNamesProps = {
    container: classNames(styles.spinnerContainer, styleValues.classNameContainerProp),
    spinner: classNames(styles.spinner, styleValues.classNameSpinnerProp)
  };

  return (
    <div className={classNamesProps.container}>
      <div
        style={{
          '--width': `${styleValues.width}px`,
          '--height': `${styleValues.height}px`,
          '--spinnerSize': `${-(styleValues.height / 2)}px`,
          ...styleValues.spinnerVarProps
        }}
        className={classNamesProps.spinner}
      ></div>
    </div>
  );
};

SpinnerProgress.propTypes = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Add classes to the spinner container
       */
      classNameContainerProp: PropTypes.object,
      /**
       * Add classes to the spinner
       */
      classNameSpinnerProp: PropTypes.object,
      /**
       * Height of hourglass image
       */
      height: PropTypes.string,
      /**
       * Width of hourglass image
       */
      width: PropTypes.string
    })
  )
};

SpinnerProgress.defaultProps = {
  height: 64,
  width: 64
};

export default SpinnerProgress;
