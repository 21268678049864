import logger from '../../logger';
import { EXTRACTED_MODEL } from '../../constants/model.constants';
import { extractFile, getZippedObject } from '../../unzip-util';
import { downloadFile } from '../download-file';
import { Environments } from '../../constants/environment.constants';
import { ScanSystemTypes } from '../../constants/scannerSystemTypes.constants';
import requestsManager from '../requests-manager';

import {
  getModelByMetadata,
  getModelTextures,
  waitForItrToBeCreated,
  waitForNiriToBeCreated,
} from './itr-fetcher.service.logic';
import { logToTimberBI, biMethods } from '../../timberLogger';

export const extractItrModel = async (arraybufferData, selector) => {
  try {
    logger.time('extracting  ' + selector);

    let zippedModel = await getZippedObject(arraybufferData);
    let systemType = ScanSystemTypes.Element;
    const metaDataStr = await extractFile(zippedModel, 'data.json', 'string');

    const metaDataObj = JSON.parse(metaDataStr.trim());
    const model = await getModelByMetadata(metaDataObj, EXTRACTED_MODEL, zippedModel);
    const textures = await getModelTextures(zippedModel, metaDataObj);
    const { tags } = metaDataObj;
    if (tags) {
      const { SystemType } = tags;
      systemType = SystemType;
    }

    model.textures = textures;
    model.id = new Date().getTime();
    model.itrFileVersion = metaDataObj.version || '1.0.0';
    model.originScanner = systemType;

    const timeToExtractZip = logger.timeEnd('extracting  ' + selector, { module: 'itr-fetcher.service' });
    const { id, originScanner, itrFileVersion, objects } = model || {};

    logger
      .info('Extracting of ITR data - succeeded')
      .to(['analytics', 'host'])
      .data({
        module: 'itr-fetcher',
        timeToExtract: timeToExtractZip,
        data: JSON.stringify({ id, originScanner, itrFileVersion, objects: Object.keys(objects) }),
      })
      .end();

    return model;
  } catch (err) {
    logger
      .error('Extracting of ITR data - failed')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', err })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'itr-fetcher',
        code: 'ITR Loading Error',
        description: err.message,
        severity: 'Fatal - Extracting of ITR data - failed',
      })
    );

    return Promise.reject(err);
  }
};

export const fetchNiriFileWithPolling = async ({ environment, requestParams, progressCB }) => {
  try {
    let response = await downloadFile({
      fileType: 'niri',
      options: { credentials: 'include' },
      progressCB,
      ...requestParams,
    });

    if (environment === Environments.EUP && response.status === 404) {
      throw new Error('No NIRI file path');
    }

    if (environment === Environments.SCANNER && response.status === 404) {
      return await waitForNiriToBeCreated(requestParams, progressCB);
    }

    return response;
  } catch (err) {
    logger
      .error('fetchNiriFileWithPolling: Downloading of NIRI data - failed')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', err })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'itr-fetcher',
        code: 'NIRI downloading Error',
        description: err.message,
        severity: 'fetchNiriFileWithPolling: Downloading of NIRI data - failed',
      })
    );
    return Promise.reject(err);
  }
};

export const getITRModelWithPolling = async (environment, requestParams) => {
  const { selector } = requestParams;
  const response = await fetchModelWithPolling(environment, requestParams);
  const arraybufferData = await response.arrayBuffer();
  return await extractItrModel(arraybufferData, selector);
};

const fetchModelWithPolling = async (environment, requestParams) => {
  try {
    let response = await downloadFile({
      fileType: 'itr',
      ...requestParams,
    });

    if (environment === Environments.EUP && response.status === 404) {
      let hasItrCreated = await waitForItrToBeCreated(environment, requestParams);
      if (hasItrCreated) {
        response = await downloadFile({
          force: true,
          fileType: 'itr',
          ...requestParams,
        });
      }
    }

    if (environment === Environments.SCANNER && response.status === 404) {
      response = await waitForItrToBeCreated(Environments.SCANNER, requestParams);
    }

    return response;
  } catch (err) {
    logger
      .error('fetchModelWithPolling: Downloading of ITR data - failed')
      .to(['analytics', 'host'])
      .data({ module: 'itr-fetcher', err })
      .end();

    logToTimberBI(
      biMethods.errorReportBiLog({
        object: 'itr-fetcher',
        code: 'ITR downloading Error',
        description: err.message,
        severity: 'fetchModelWithPolling: Downloading of ITR data - failed',
      })
    );
    return Promise.reject(err);
  }
};

export const getRequestManager = () => requestsManager;
