import { EventDispatcher } from 'three';

import TouchPointerAdapter from './touch-pointer-adapter';

const touchAdapter = new TouchPointerAdapter();

const tapEvent = { type: 'tap2Fingers' };
const doubletapEvent = { type: 'doubletap2Fingers' };

const defaultOptions = {
  tapDuration: 150
};

class TapEvent extends EventDispatcher {
  constructor(element, options = {}) {
    super();

    this.element = element;
    this.options = { ...defaultOptions, ...options };
  }

  _tapCount = 0;
  _tapTimeoutID = 0;
  _touchStartTime = 0;

  init() {
    this._addEventListeners();
  }

  dispose() {
    this._removeEventListeners();
  }

  // -------------- Event handlers ---------------

  _pointerdown = event => {
    touchAdapter.pointerdown(event);

    window.clearTimeout(this._tapTimeoutID);

    switch (touchAdapter.touches.length) {
      case 1:
        break;
      case 2:
        this._touchStartTime = Date.now();
        break;
      default:
      // no default
    }
  };

  _pointerup = event => {
    touchAdapter.pointerup(event);

    if (!touchAdapter.touches.length) {
      const touchEndTime = Date.now();
      const touchDuration = touchEndTime - this._touchStartTime;

      const tap = touchDuration <= this.options.tapDuration;

      this._tapCount = tap ? this._tapCount + 1 : 0;

      switch (this._tapCount) {
        case 1:
          this._tapTimeoutID = setTimeout(() => {
            this._tapCount = 0;
            this.dispatchEvent(tapEvent);
          }, this.options.tapDuration);
          break;
        case 2:
          this._tapCount = 0;
          this.dispatchEvent(doubletapEvent);

          break;
        default:
        // no default
      }
    }
  };

  _addEventListeners() {
    this.eventListeners = {
      pointerdown: this._pointerdown,
      pointerup: this._pointerup
    };

    for (let eventType in this.eventListeners) {
      this.element.addEventListener(eventType, this.eventListeners[eventType], false);
    }
  }

  _removeEventListeners() {
    for (let eventType in this.eventListeners) {
      this.element.removeEventListener(eventType, this.eventListeners[eventType], false);
    }
    for (let eventType in this._listeners) {
      this._listeners[eventType] = [];
    }
  }
}

export { TapEvent };
