import { eventBus, hostEvents } from '../../event-bus';

export default (() => {
  const getFeaturesToggleSettings = [
    { id: 'DataRequestRthFile', isActive: true, origin: 1 },
    { id: 'FlapsDetection', isActive: true, origin: 1 },
    { id: 'IOSimPlus', isActive: true, origin: 1 },
    { id: 'Enable2DColorImprovement', isActive: true, origin: 1 },
    { id: 'AutoNewSegment', isActive: true, origin: 1 },
    { id: 'Viewer360FeatureInternalUse', isActive: true, origin: 1 },
    { id: 'AdjustIOSimPlusPrefsBeforehand', isActive: true, origin: 1 },
    { id: 'Enable3DPhotorealisticShader', isActive: false, origin: 1 },
    { id: 'SideBySideCompare', isActive: true, origin: 1 },
    { id: 'Acs', isActive: true, origin: 1 },
    { id: 'PatientManagement', isActive: false, origin: 1 },
    { id: 'TextureMapping', isActive: true, origin: 1 },
    { id: 'TextureMappingOnElement1', isActive: true, origin: 1 },
    { id: 'TextureMappingOnElement2', isActive: true, origin: 1 },
    { id: 'TextureMappingOnFlex', isActive: true, origin: 1 },
    { id: 'TextureMappingOnPPC', isActive: true, origin: 1 },
    { id: 'TextureMappingOnMPPC', isActive: true, origin: 1 },
    { id: 'TextureMappingOnFlexPlus', isActive: true, origin: 1 },
    { id: 'EnableTrendMicroApexOneUpdateFeature', isActive: true, origin: 1 },
    { id: 'ConsentCheckbox', isActive: true, origin: 1 },
    { id: 'DentalXr', isActive: true, origin: 1 },
  ];
  const getCompanySoftwareOptions = [
    2086,
    2090,
    2100,
    2102,
    2104,
    2105,
    2108,
    2220,
    2221,
    2225,
    2226,
    2249,
    2252,
    2253,
    2267,
    2403,
  ];
  const getPatientOrdersForComparison = [
    { orderId: '17714605', scanDate: '2023-01-17T12:20:33', isOrtho: true, isTimelapseEnabled: true },
    { orderId: '17715149', scanDate: '2023-01-18T14:35:33', isOrtho: true, isTimelapseEnabled: true },
    { orderId: '44722467', scanDate: '2023-01-18T15:21:10', isOrtho: false, isTimelapseEnabled: false },
    { orderId: '12345', scanDate: '2023-01-18T15:45:41', isOrtho: false, isTimelapseEnabled: false }, //preps margin ditch
  ];

  const areScannerSoftwareOptionsAvailable = {
    2087: true,
    2092: false,
    2230: true,
    2231: false,
    2249: false,
    2403: true,
    2406: false,
  };

  const getMockOrdersMetadataForComparison = () => {
    setTimeout(() => {
      eventBus.raiseEvent(hostEvents.TIMELAPSE_DATA, [...getPatientOrdersForComparison]);
    }, 30000);
  };

  return {
    getFeaturesToggleSettings,
    getCompanySoftwareOptions,
    getPatientOrdersForComparison,
    areScannerSoftwareOptionsAvailable,
    getMockOrdersMetadataForComparison,
  };
})();
