import { get } from 'lodash';
import { Vector3, MathUtils, Vector2 } from 'three';
import { get_camera_pt } from '../utils/threeUtils';
import const_params from '../const_params';
import { has_data, logged_assert, get_2d_point_in_image_px } from '../lumina.helper';

const {
  max_distance_to_selected_pt_mm,
  max_dist_from_cam_to_img_cen_on_surf,
  angie_max_distance_between_pr_pt_and_cam_mm,
  max_angle_difference_between_view_and_image,
  pix_dist_coef,
  angie_height_coef,
} = const_params;
const invalid_image_index = -1;

export const getDistanceFromLoupeCenterToImageCenter = (photoObj, zCamAxis, imagesCenter, intersect) => {
  const RATIO_PIXEL_TO_ANGLE = 600;
  const point = get(intersect, 'point');
  let distance = Number.MAX_VALUE;
  ['niri', 'color'].forEach((key) => {
    const item = get(photoObj, key) || {};
    const worldToCam = item.worldToCamMatrix;
    if (!worldToCam) {
      return;
    }
    const screenPoint = point.clone();
    screenPoint.applyMatrix4(worldToCam);
    screenPoint.set(screenPoint.x / screenPoint.z, screenPoint.y / screenPoint.z, 0);
    const rotationVector = new Vector3(...item.rawImageMatrix.slice(8, 11));
    const dotProduct = rotationVector.dot(zCamAxis);

    const distanceFromImageCenter = screenPoint.distanceTo(imagesCenter);
    distance = 1 - dotProduct + distanceFromImageCenter / RATIO_PIXEL_TO_ANGLE;
  });
  return distance;
};

export const selectBestMatchFrameOnModelRotation = (
  rayFromLoupeViewDirection,
  jawName,
  intersect,
  jawsPhotosMetadata,
  luminaImageMetaData,
  zCamAxis
) => {
  const view_direction = rayFromLoupeViewDirection;
  const currentActiveJaw = get(jawsPhotosMetadata, jawName);
  const images_meta_data = luminaImageMetaData;

  const compute_position_value_and_direction = (image_idx, penalty_value, selected_pt_on_image_px, intersect_pt) => {
    const currentImageData = images_meta_data[image_idx];
    const {
      scan_role,
      was_cam_projected,
      img_cen_on_surf_pt,
      camera_pt,
      camera_dir,
      average_cam_height,
      dist_from_cam_to_surf,
      cam_to_abs_tx,
      rect_of_image,
    } = currentImageData;
    if (!has_data(currentImageData) || scan_role !== jawName) {
      logged_assert(currentImageData, 'Invalid image index: most likely image meta data was not precomputed');
      return false;
    }

    // 1. Constraint: the distance between loupe position and image center projected on surface should not differ too much.
    // Although similar criteria is utilised below, they rely on the fact that projected image center is actually visible from
    // corresponding camera. However, it might happen that image center on surface is not visible from camera, but belongs to the
    // region of interest. This is due to the fact the we do not apply rasterization/ray tracing, while projection algorithm
    let intersectPointVectorLength = 0;
    if (was_cam_projected) {
      intersectPointVectorLength = new Vector3().subVectors(intersect_pt, img_cen_on_surf_pt).length();
      if (intersectPointVectorLength > max_dist_from_cam_to_img_cen_on_surf) return false;
    } else {
      intersectPointVectorLength = new Vector3().subVectors(intersect_pt, camera_pt).length();
      if (intersectPointVectorLength > angie_max_distance_between_pr_pt_and_cam_mm) return false;
    }

    // 2. Constraint
    const cameraDirectionVector = new Vector3().copy(camera_dir);
    const angle_diff = cameraDirectionVector.angleTo(view_direction);

    if (angle_diff > MathUtils.degToRad(max_angle_difference_between_view_and_image)) return false;

    // 3. Constraint: loupe should actually belong to the region of interest
    selected_pt_on_image_px = new Vector2().addVectors(
      new Vector2(rect_of_image.x_min, rect_of_image.y_min),
      get_2d_point_in_image_px(intersect, currentActiveJaw[image_idx])
    );

    if (!rect_of_image.includesPoint(new Vector2().set(selected_pt_on_image_px.x, selected_pt_on_image_px.y)))
      return false;

    // 4. Compute compliance coefficient with current view. For the second round select only those images that pass a predefined threshold
    const centerFromRoi = rect_of_image.center();
    const image_center_pt = new Vector2(centerFromRoi.x, centerFromRoi.y);
    const distance_px = new Vector2().subVectors(image_center_pt, selected_pt_on_image_px).length(); // distance from loupe intersect point on image to the center of the image

    const height_diff = was_cam_projected
      ? Math.abs(average_cam_height - dist_from_cam_to_surf)
      : Math.abs(average_cam_height - new Vector3().subVectors(intersect_pt, get_camera_pt(cam_to_abs_tx)).length());

    const angle_penalty = angle_diff;
    const pix_dist_penalty = distance_px * pix_dist_coef;
    const height_penalty = height_diff * angie_height_coef;

    penalty_value = angle_penalty + pix_dist_penalty + height_penalty;

    return {
      img_idx: image_idx,
      penalty: penalty_value,
      p2: selected_pt_on_image_px,
    };
  };

  // Image to be selected
  const maxNumber = Number.MAX_VALUE;
  const p2 = new Vector2(0, 0);
  let image_to_show = { img_idx: invalid_image_index, penalty: maxNumber, p2 };

  // Part 1: compute general compliance coefficient
  let last_penalty = 0;

  for (let i = 0; i < currentActiveJaw.length; ++i) {
    let intersect_pt = get(intersect, 'point');
    const selected_pt_on_image_px = new Vector2(0, 0);
    const distanceToCurrentPt = getDistanceFromLoupeCenterToImageCenter(
      currentActiveJaw[i],
      zCamAxis,
      new Vector3(...images_meta_data[i].rect_of_image.center().toArray()),
      intersect
    );

    if (distanceToCurrentPt < max_distance_to_selected_pt_mm) {
      const view_direction_to_scalar_v = new Vector3().fromArray(
        Object.values(view_direction).map((v) => v * distanceToCurrentPt)
      );
      intersect_pt = new Vector3().subVectors(intersect_pt, view_direction_to_scalar_v);
    }

    const positionValueAndDirection = compute_position_value_and_direction(
      i,
      last_penalty,
      selected_pt_on_image_px,
      intersect_pt
    );

    if (!positionValueAndDirection || (last_penalty !== 0 && positionValueAndDirection.penalty > last_penalty))
      continue;

    last_penalty = positionValueAndDirection.penalty;
    image_to_show = {
      img_idx: positionValueAndDirection.img_idx,
      penalty: positionValueAndDirection.penalty,
      p2: positionValueAndDirection.p2,
    };
  }

  return { img_idx: image_to_show.img_idx, image: currentActiveJaw[image_to_show.img_idx], p2: image_to_show.p2 };
};
