import React from 'react';
import { cacheManager, cacheKeys, logger } from '@web-3d-tool/shared-logic';

export const initFallbackShaders = props => {
  // this function is a workaround for catching the erros from WEBGL that cannot be catched with trycatch.
  const FALLBACK_SHADER_ERROR = cacheManager.get(cacheKeys.FALLBACK_SHADER_ERROR) || { error: null, count: 0 };

  console.error = error => {
    const glslBlasklist = ['THREE.WebGLProgram', 'shader', 'Vertex', 'Fragment'];
    if (
      error &&
      FALLBACK_SHADER_ERROR &&
      glslBlasklist.some(entry =>
        JSON.stringify(error)
          .toLocaleLowerCase()
          .includes(entry.toLocaleLowerCase())
      )
    ) {
      logger
        .info('Error was thrown from WEBGL. Fallback shader will be loaded.')
        .to(['host'])
        .data({ module: 'FallbackMeshWithTextureMapping', event: 'console.error event' })
        .end();

      FALLBACK_SHADER_ERROR.error = error;
      FALLBACK_SHADER_ERROR.count += 1;
      cacheManager.set(cacheKeys.FALLBACK_SHADER_ERROR, FALLBACK_SHADER_ERROR);
      clearScene();
      props.forceUpdate();
    }
    console.warn(error);
  };
};

export const clearScene = () => {
  const THREE_OBJECTS = cacheManager.get(cacheKeys.THREE_OBJECTS);
  const { group, scene } = THREE_OBJECTS;

  if (group) {
    group.children.forEach(mesh => {
      mesh.geometry.dispose();
      mesh.material.texture.dispose();
      mesh.material.dispose();
      scene.remove(mesh);
    });
  }
};

export const shouldRenderMeshWithoutTMIfFallbackFails = () => {
  const FALLBACK_SHADER_ERROR = cacheManager.get(cacheKeys.FALLBACK_SHADER_ERROR) || { error: null, count: 0 };
  const { count } = FALLBACK_SHADER_ERROR;
  const shouldRenderWithoutTM = count > 5;

  if (shouldRenderWithoutTM) {
    logger
      .info('Fallback shader failed to load. Model will be rendered without TM.')
      .to(['host'])
      .data({ module: 'FallbackMeshWithTextureMapping', function: 'shouldRenderMeshWithoutTMIfFallbackFails' })
      .end();
  }

  return shouldRenderWithoutTM;
};

const FallbackMeshWithTextureMapping = props => {
  const { geometry, map, error } = props;

  logger
    .info('Error loading new Lumina shader, Fallback shader will be loaded')
    .to(['host'])
    .data({ module: 'FallbackMeshWithTextureMapping', function: 'FallbackMeshWithTextureMapping', error: error })
    .end();

  return (
    <mesh geometry={geometry}>
      {<textureMaterial name="TextureMaterial" attach="material" texture={map} map={map} map-flipY={false} />}
    </mesh>
  );
};

export default FallbackMeshWithTextureMapping;
