export default Object.freeze({
  max_distance_to_selected_pt_mm: 9.0,
  max_dist_from_cam_to_img_cen_on_surf: 14.0,
  angie_max_distance_between_pr_pt_and_cam_mm: 25.0,
  pix_dist_coef: 1 / 1500.0,
  angie_height_coef: 1 / 30,
  Z_INDEX_CENTER_OF_ROTATION_OFFSET: 0.5,
  rail_max_return_time: 3000, //ms
  surface_projection_segment_size: 1.0,
  preferable_angle_difference_between_view_and_image: 40.0,
  projection_distance_compliance_coefficient: 0.5,
  angle_compliance_coefficient: 0.35,
  max_angle_difference_between_view_and_image: 90.0,
  max_rail_candidates: 50,
  rail_score_threshold: 0.0,
  max_position_difference_between_sector_center_and_image_projection: 10.0, // image selection radius top end.
  min_image_capture_height: 8.0,
  max_image_capture_height: 50.0,
  preferable_image_capture_height: 8.0,
  preferable_position_difference_between_sector_center_and_image_projection: 6.0, // image selection radius preferable range.
  position_compliance_coefficient: 0.5,
  image_capture_height_compliance_coefficient: 0.15,
  minimal_distance_approximation: 0.1,
  replacement_threshold: 1,
  camera_count: 6,
});
