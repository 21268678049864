import {
  logger,
  hostCommunicationManager,
  eventBus,
  globalEventsKeys,
  cacheManager,
  cacheKeys,
  utils,
  settingsManager,
  scannerVersionManager,
  Environments,
  interceptor,
  appSettingsService,
  featureAvaliability,
  sctService,
} from '@web-3d-tool/shared-logic';
import { lazyLoadPreset, composePreset } from '@web-3d-tool/plugins';
import * as configValues from '@web-3d-tool/shared-logic/src/constants/configurationValues.constants';
import { loadLanguage } from '@web-3d-tool/localization';
import * as AT from '../../actionTypes';
import { loadPreset, setAppPreset, setWindowSize } from './shell.actions';
import { loadModel } from '../renderer/renderer.actions';
import createMiddleware from './../../../middlewareHelper';
import { loadPlugins, clearPlugins } from '../plugins/plugins.actions';
//******************************
const feature = AT.SHELL;
//******************************

export const goThroughOverride = ({ action, dispatch, getState }) => {};
export const middleware = async ({ action, dispatch, getState }) => {
  const { payload, type } = action;
  if (!payload || !type) {
    return;
  }
  switch (type) {
    case AT.APP_LOADED:
      {
        const isEup = utils.getEnv() === Environments.EUP;
        const state = getState();
        if (state) {
          logger
            .info('Initializing')
            .to(['analytics', 'host'])
            .data({ module: 'app shell', state: JSON.stringify(state.shell) })
            .end();
        }
        hostCommunicationManager.initHostCommunicationManager();

        settingsManager.init();
        await loadLanguage();

        if (isEup) {
          await appSettingsService.requestSingle('getFeaturesToggleSettings');

          const isSCTEnabled = featureAvaliability.getIsSCTEnabled();
          const isWeb3DToolSCTEnabled = featureAvaliability.getIsWeb3DToolSCTEnabled();

          if (isSCTEnabled && isWeb3DToolSCTEnabled) {
            const [error] = await sctService();
            error && (await interceptor({}));
          } else {
            await interceptor({});
          }
        }

        const is23AScannerVersion =
          scannerVersionManager.getScannerVersion() === scannerVersionManager.scannerAssetsVersions.v23A;
        (isEup || is23AScannerVersion) && (await appSettingsService.getAllSettings());

        eventBus.raiseEvent(globalEventsKeys.APP_LOADED);
        dispatch([loadModel(), loadPreset({ pluginsPresetId: 'default' })]);
      }
      break;

    case AT.LOAD_PRESET:
      {
        const presetId =
          getState().shell.presetId ||
          (utils.getIs360HubEnabled() ? '360' : settingsManager.getConfigValue(configValues.mode));
        const { forceReloadPreset, pluginsPresetId, initiatingPlugin } = payload;

        try {
          let preset;
          if (forceReloadPreset) {
            preset = await lazyLoadPreset(presetId);
          } else {
            preset = cacheManager.get(cacheKeys.PRESET) || (await lazyLoadPreset(presetId));
          }

          cacheManager.set(cacheKeys.PRESET, preset);
          const appPreset = await composePreset({ preset, pluginsPresetId });
          dispatch([
            clearPlugins({ initiatingPlugin }),
            setAppPreset({ appPreset }),
            loadPlugins({ initiatingPlugin }),
          ]);
        } catch (err) {
          logger
            .error('LOAD_PRESET')
            .to(['analytics', 'host'])
            .data({ module: 'app shell', err: err })
            .end();
        }
      }
      break;

    case AT.WINDOW_BEING_RESIZED:
      {
        const { width, height } = payload;
        dispatch(setWindowSize({ width, height }));
      }
      break;

    default:
      //do nothing
      break;
  }
};

export default createMiddleware({
  feature,
  goThroughOverride,
})(middleware);
