import { cacheKeys, cacheManager } from '../cache-manager';
import { ViewerSoftwareOptions, SoftwareOptionsForCompany } from '../constants/enums.constants';
import { TIMELAPSE, MODEL_COMPARE, IOSIM_PLUS, DENTAL_XRAI } from '../constants/tools.constants';

const getAppSettingsFromCache = () => {
  const appSettings = cacheManager.get(cacheKeys.APP_SETTINGS) || {};
  return appSettings;
};

const getAllAvaliableFeaturesIds = () => {
  const avaliableFeaturesArray = [];
  const isModelCompareEnabled = isSideBySideCompareEnabled();
  const isIOSimPlusEnabled = getIsIOSimPlusEnabled();
  const isTimeLapseEnabled = getIsTimeLapseEnabled();
  const isDentalXraiEnabled = getIsDentalXraiEnabled();
  isDentalXraiEnabled && avaliableFeaturesArray.push(DENTAL_XRAI.id);
  isModelCompareEnabled && avaliableFeaturesArray.push(MODEL_COMPARE.id);
  isIOSimPlusEnabled && avaliableFeaturesArray.push(IOSIM_PLUS.id);
  isTimeLapseEnabled && avaliableFeaturesArray.push(TIMELAPSE.id);
  return avaliableFeaturesArray;
};

const getPatientOrdersForComparison = () => {
  const { getPatientOrdersForComparison } = getAppSettingsFromCache();
  const { orders } = getPatientOrdersForComparison || {};
  if (orders) {
    const ordersForComparison = orders.map((order) => ({ orderId: order.id, scanDate: order.scanDate }));
    return ordersForComparison || [];
  }
  return getPatientOrdersForComparison || [];
};

const getAvaliabilityForPluginOrFeature = ({ featureFlagNames, companySwo, scannerSwo, resolveFunction }) => {
  const {
    areScannerSoftwareOptionsAvailable,
    getCompanySoftwareOptions,
    getFeaturesToggleSettings,
  } = getAppSettingsFromCache();
  const isScannerSwoEnabled = !!(
    areScannerSoftwareOptionsAvailable &&
    areScannerSoftwareOptionsAvailable.hasOwnProperty(scannerSwo) &&
    areScannerSoftwareOptionsAvailable[scannerSwo] === true
  );
  const isCompanySwoEnabled = getCompanySoftwareOptions && getCompanySoftwareOptions.includes(companySwo);
  const isFeatureFlagEnabled = !!(
    getFeaturesToggleSettings && getFeaturesToggleSettings.find((ff) => featureFlagNames.includes(ff.id) && ff.isActive)
  );
  return resolveFunction(isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled);
};

const isSideBySideCompareEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isScannerSwoEnabled;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: ['SideBySideCompare'],
    scannerSwo: ViewerSoftwareOptions.SideBySideCompare,
    resolveFunction,
  });
};

const getIsEVxEnabled = () => {
  // TODO implement
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return true;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: [],
    companySwo: null,
    resolveFunction,
  });
};

const getIsIOCEnabled = () => {
  // TODO implement
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return true;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: [],
    companySwo: null,
    resolveFunction,
  });
};

const getIsTimeLapseEnabled = () => {
  // TODO implement
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return true;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: [],
    companySwo: null,
    resolveFunction,
  });
};

const getIsTextureMappingDisabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return true;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagName: ['TextureMapping', 'TextureMapping.IsEnabled'],
    scannerSwo: ViewerSoftwareOptions.ShouldEnableTextureMappingInViewer,
    resolveFunction,
  });
};

const getIsIOSimPlusEnabled = () => {
  // TODO implement
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    const isIOSimPlusEnabled = false;
    const iosimProps = {
      expectedDuration: null,
      simulationStatus: null,
      startSimulationTime: null,
    };
    return true;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: [],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsDentalXraiEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, companySwo, scannerSwo) => {
    return scannerSwo;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: ['DentalXr'],
    scannerSwo: ViewerSoftwareOptions.DentalXr,
    resolveFunction,
  });
};

const getIsSCTEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: ['use-sct-authorization'],
    scannerSwo: null,
    resolveFunction,
  });
};

const getIsWeb3DToolSCTEnabled = () => {
  const resolveFunction = (isFeatureFlagEnabled, isCompanySwoEnabled, isScannerSwoEnabled) => {
    return isFeatureFlagEnabled;
  };

  return getAvaliabilityForPluginOrFeature({
    featureFlagNames: ['UseSctWeb3dToolAuthorization'],
    scannerSwo: null,
    resolveFunction,
  });
};

export const featureAvaliability = {
  isSideBySideCompareEnabled,
  getPatientOrdersForComparison,
  getIsDentalXraiEnabled,
  getAllAvaliableFeaturesIds,
  getIsWeb3DToolSCTEnabled,
  getIsSCTEnabled,
};
