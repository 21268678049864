import { logToTimber } from '../timber';

export default {
  app: {},
  plugins: {},
  shared_logic: {},
  shared_ui: {
    imageFrame: {
      imageFrameTimberLog: ({ eventType, eventSource }) => {
        const module = eventSource === 'niri' ? 'NIRI Panel' : 'IOC Panel';
        const type = 'Panel';
        let eventName = '';

        if (eventType === 'zoom') {
          eventName = eventSource === 'niri' ? 'NIRI Panel - Zoom-web viewer' : 'IOC Panel - Zoom-web viewer';
        } else {
          eventName = eventSource === 'niri' ? 'NIRI Panel - Pan-web viewer ' : 'IOC Panel - Pan-web viewer';
        }

        logToTimber({
          timberData: {
            eventName,
            action: eventType,
            module,
            type,
          },
        });
      },
    },
  },
};
