import { eupRequests } from './eup-requests';
import { scannerRequests } from './scanner-requests';
import { utils } from '../utils';
import { Environments } from '../constants/environment.constants';

const requestsManager = {
  ...(utils && utils.getEnv() === Environments.EUP ? eupRequests : scannerRequests)
};

export default requestsManager;
