const apiMap = {
  getModelByType: {
    method: 'GET',
    path: '',
    pathScanner: 'webviewer/getModelByType'
  },
  GetItrFile: {
    method: 'GET',
    path: 'mvc/orderfiles/GetItrFile',
    pathScanner: 'webviewer/getModel'
  },
  GetEvxFile: {
    method: 'GET',
    path: 'mvc/orderfiles/GetEvxFile',
    pathScanner: 'webviewer/getModel'
  },
  IsItrFileExists: {
    method: 'GET',
    path: 'mvc/orderfiles/IsItrFileExists/',
    pathScanner: ''
  },
  getFeaturesToggleSettings: {
    method: 'GET',
    path: 'mvc/featuresToggleSettings/getFeaturesToggleSettings',
    pathScanner: 'webviewer/getFeaturesToggleSettings'
  },
  getCompanySoftwareOptions: {
    method: 'GET',
    path: 'mvc/settings/getCompanySoftwareOptionIds',
    pathScanner: 'webviewer/getCompanySoftwareOptions'
  },
  getPatientOrdersForComparison: {
    method: 'GET',
    path: 'mvc/patientsOrders/orders',
    pathScanner: 'webviewer/getPatientOrdersForComparison'
  },
  getXraiAuthToken: {
    method: 'GET',
    path: '',
    pathScanner: 'webviewer/getXraiAuthToken'
  },
  areScannerSoftwareOptionsAvailable: {
    method: 'GET',
    path: 'mvc/settings/areScannerSoftwareOptionsAvailable',
    pathScanner: 'webviewer/areScannerSoftwareOptionsAvailable'
  }
};

export const apiMapKeys = (() => {
  const apiMapKeysObj = {};
  Object.keys(apiMap).forEach(key => {
    apiMapKeysObj[key] = key;
  });
  return apiMapKeysObj;
})();

export default apiMap;
