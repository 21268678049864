import { apiHeaders } from '../constants/apiEnums';
import { utils } from '../utils';
import { eventBus, globalEventsKeys } from '../event-bus';
import { logToGAAndTimber, addHeaders } from '../api-service/apiService-helper';
import { getTimberUrls } from '../timberLogger/timber';
import refreshRequest from '../api-service/refreshService';

const SESSION_INFO = 'sessionInfo';
const { X_Company_ID, X_Doctor_ID } = apiHeaders;
const { getDoctorId, getCompanyId, isStandaloneMode } = utils;

const setEupCustomHeaders = () => {
  const eupCustomHeaders = {
    [X_Doctor_ID]: getDoctorId(),
    [X_Company_ID]: getCompanyId(),
  };
  return eupCustomHeaders;
};

const setSCTHeaders = (isSCTEnabled) => {
  if (isSCTEnabled) {
    const sessionInfo = JSON.parse(localStorage.getItem(SESSION_INFO));
    const { accessToken } = sessionInfo || {};
    return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
  }
  return {};
};

const getRestrictedOriginsForSession = async () => {
  const [timberBiUrl, timberUrl] = await getTimberUrls();
  return [timberBiUrl, timberUrl];
};

export const setSessionHeaders = ({ headers = new Headers(), isSCTEnabled }) => {
  const authorizationHeader = setSCTHeaders(isSCTEnabled);
  const eupCustomHeaders = setEupCustomHeaders();
  const sessionHeadersObject = { ...authorizationHeader, ...eupCustomHeaders };
  return addHeaders({ headersObj: sessionHeadersObject, headers });
};

export default ({ isSCTEnabled = false }) => {
  return new Promise((resolve) => {
    if (isStandaloneMode()) {
      return resolve();
    }

    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
      const [resource, config] = args;
      const { headers, options } = config || {};
      const isRefreshRequest = headers && headers.get('refresh') === 'true';
      const restrictedOrigins = await getRestrictedOriginsForSession();
      const shouldSetSessionHeaders = headers && !restrictedOrigins.includes(resource);
      shouldSetSessionHeaders && setSessionHeaders({ headers, isSCTEnabled });

      const response = await originalFetch(resource, config);

      if (response.status === 401 && !isRefreshRequest) {
        return new Promise(async (resolve) => {
          eventBus.subscribeToEvent(globalEventsKeys.REFRESH_TOKEN, async ({ isRefreshed }) => {
            return resolve(isRefreshed ? await originalFetch(resource, config) : response);
          });
          await refreshRequest();

          logToGAAndTimber({
            url: resource,
            options: options || {},
            isForced: false,
            howRequestWasProcessed: 'Failed',
            errorMessage: `Unauthorized response while fetching url: ${resource} - status: ${response.status}`,
            module: 'interceptor',
          });
        });
      } else {
        return response;
      }
    };
    resolve();
  });
};
